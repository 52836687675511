import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import {InputTextModule} from "primeng/inputtext";
import {AvatarModule} from 'primeng/avatar';
import {MultiSelectModule} from 'primeng/multiselect';

import {FormLabelComponent} from "./components/form-label/form-label.component";
import {FormErrorComponent} from "./components/form-error/form-error.component";
import {FormInputIconWrapperComponent} from './components/form-input-icon-wrapper/form-input-icon-wrapper.component';
import {
    EntitySelfManagementLayoutComponent
} from './components/entity-self-management-layout/entity-self-management-layout.component';
import {RoleLabelPipe} from './pipes/role-label.pipe';
import {RoleClassPipe} from './pipes/role-class.pipe';
import {NumDEPipe} from './pipes/num-de';
import {CommaJoinPipe} from './pipes/comma-join.pipe';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {AbsPipe} from './pipes/abs.pipe';
import {PopupContainerComponent} from './components/popup-container/popup-container.component';
import {DataGridComponent} from './components/data-grid/data-grid.component';
import {NumberToArrayPipe} from './pipes/number-to-array.pipe';
import {
    AsyncInitializationLoadingOverlayComponent
} from './components/async-initialization-loading-overlay/async-initialization-loading-overlay.component';
import {ProgressBarModule} from "primeng/progressbar";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ButtonModule} from "primeng/button";
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { KEurShortPipe } from './pipes/k-eur-short.pipe';
import { KEurLongPipe } from './pipes/k-eur-long.pipe';
import { OrgStatusBadgeComponent } from './components/org-status-badge/org-status-badge.component';
import { OrgLicenseTypeTagComponent } from './components/org-license-type-tag/org-license-type-tag.component';
import { TagModule } from "primeng/tag";
import { UserStatusBadgeComponent } from './components/user-status-badge/user-status-badge.component';
import { StamoFilterComponent } from './components/stamo-filter/stamo-filter.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PctNumDEPipe } from './pipes/pct-num-de.pipe';
import { StamoRangeSliderComponent } from './components/stamo-range-slider/stamo-range-slider.component';
import { SliderModule } from 'primeng/slider';
import { WzIdPipe } from './pipes/wz-id.pipe';
import { StamoSliderComponent } from './components/stamo-slider/stamo-slider.component';


@NgModule({
  declarations: [
    FormLabelComponent,
    FormErrorComponent,
    FormInputIconWrapperComponent,
    EntitySelfManagementLayoutComponent,
    RoleLabelPipe,
    RoleClassPipe,
    NumDEPipe,
    CommaJoinPipe,
    AbsPipe,
    PopupContainerComponent,
    DataGridComponent,
    NumberToArrayPipe,
    NumberToArrayPipe,
    AsyncInitializationLoadingOverlayComponent,
    LoadingDialogComponent,
    KEurShortPipe,
    KEurLongPipe,
    PctNumDEPipe,
    WzIdPipe,
    OrgStatusBadgeComponent,
    OrgLicenseTypeTagComponent,
    UserStatusBadgeComponent,
    StamoFilterComponent,
    CopyToClipboardComponent,
    StamoRangeSliderComponent,
    StamoSliderComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InputTextModule,
        AvatarModule,
        MultiSelectModule,
        OverlayPanelModule,
        CheckboxModule,
        DropdownModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        ButtonModule,
        DialogModule,
        TagModule,
        ClipboardModule,
        ScrollingModule,
        SliderModule
    ],
  exports: [
    RouterModule,
    FormsModule,
    FormLabelComponent,
    FormErrorComponent,
    FormInputIconWrapperComponent,
    EntitySelfManagementLayoutComponent,
    RoleLabelPipe,
    RoleClassPipe,
    NumDEPipe,
    CommaJoinPipe,
    AbsPipe,
    PopupContainerComponent,
    DataGridComponent,
    NumberToArrayPipe,
    AsyncInitializationLoadingOverlayComponent,
    LoadingDialogComponent,
    KEurShortPipe,
    KEurLongPipe,
    PctNumDEPipe,
    WzIdPipe,
    OrgStatusBadgeComponent,
    OrgLicenseTypeTagComponent,
    UserStatusBadgeComponent,
    StamoFilterComponent,
    ScrollingModule,
    StamoRangeSliderComponent,
    StamoSliderComponent
  ]
})
export class SharedModule { }
