import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NewsStateModel } from './news.model';
import { NEWS_STATE } from '..';
import { Injectable } from '@angular/core';
import { NewsService } from 'src/app/shared/services/news.service';
import { News } from './news.action';
import { tap } from 'rxjs';

@State<NewsStateModel>({
  name: NEWS_STATE,
  defaults: {
    newsList: [],
    fetching: false,
  },
})
@Injectable()
export class NewsState {
  constructor(private newsService: NewsService) {}

  @Selector()
  static newsList(state: NewsStateModel) {
    return state.newsList;
  }

  @Selector()
  static fetching(state: NewsStateModel) {
    return state.fetching;
  }

  @Action(News.GetNewsList)
  loadNewsList({ patchState }: StateContext<NewsStateModel>) {
    patchState({ fetching: true });
    return this.newsService.getNewsList().pipe(
      tap((newsList) => {
        patchState({
          fetching: false,
          newsList,
        });
      })
    );
  }
}
