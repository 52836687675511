<div class="mt-5 m-auto mb-3">
  <div class="text-center">
    <h2>SUCHERGEBNISSE</h2>
  </div>
  <div class="mb-4"><app-search-bar [withDialog]="false"></app-search-bar></div>

  <div class="mb-4">
    <app-frequent-search [selectedProductName]="selectedProductTitle" />
  </div>

  <div class="mb-4">
    <ul class="list-none m-0 p-0 w-full">
      <li
        *ngFor="let result of searchService.searchResults"
        class="select-none px-4 py-2 font-medium text-base cursor-pointer border-round-lg hover:surface-hover w-full border border-solid border-primary-500 mb-4"
      >
        <app-search-result-item [result]="result" [withIcon]="true" />
      </li>
    </ul>
  </div>

  <div class="p-1">
    <p>
      Nicht gefunden, wonach Sie suchen? Wir helfen Ihnen gerne weiter!
      <a href="mailto:support@standortmonitor.net"> Kontaktieren Sie uns. </a>
    </p>

    <p>
      {{ searchService.searchResults.length }} Ergebnisse für „{{
        searchService.searchText
      }}“ in {{ selectedProductTitle }}
    </p>
  </div>
</div>
