import { Injectable } from '@angular/core';
import {
  AuthActivateUserGQL,
  AuthChangePasswordGQL,
  AuthGetResetPasswordLinkGQL,
  AuthLoginGQL,
  AuthLoginMutationVariables,
  AuthRefreshTokenGQL,
  AuthResetPasswordGQL,
  AuthResetPasswordRequestGQL,
  AuthSetUserPasswordGQL,
  MutationAuthChangePasswordArgs,
} from '../graphql/generated/graphql';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private activateAccountGQL: AuthActivateUserGQL,
    private loginGQL: AuthLoginGQL,
    private refreshTokenGQL: AuthRefreshTokenGQL,
    private changePasswordGQL: AuthChangePasswordGQL,
    private authResetPasswordRequestGQL: AuthResetPasswordRequestGQL,
    private authResetPasswordGQL: AuthResetPasswordGQL,
    private authSetUserPasswordGQL: AuthSetUserPasswordGQL,
    private authGetResetPasswordLinkGQL: AuthGetResetPasswordLinkGQL
  ) {}

  activateAccount(token: string, newPassword: string) {
    return this.activateAccountGQL
      .mutate({ token, newPassword })
      .pipe(map((result) => result.data?.authActivateUser));
  }

  login(credentials: AuthLoginMutationVariables) {
    return this.loginGQL
      .mutate(credentials)
      .pipe(map((res) => res.data?.authLogin));
  }

  refreshTokens(refreshToken: string) {
    const context = { headers: { Authorization: `Bearer ${refreshToken}` } };
    return this.refreshTokenGQL
      .mutate({}, { context })
      .pipe(map((res) => res.data?.authRefreshToken));
  }

  resetPasswordRequest(email: string) {
    return this.authResetPasswordRequestGQL
      .mutate({ email })
      .pipe(map((result) => result.data?.authResetPasswordRequest));
  }

  resetPassword(token: string, newPassword: string) {
    return this.authResetPasswordGQL
      .mutate({ token, newPassword })
      .pipe(map((result) => result.data?.authResetPassword));
  }

  changePassword(changePasswordInput: MutationAuthChangePasswordArgs) {
    return this.changePasswordGQL
      .mutate(changePasswordInput)
      .pipe(map((result) => result.data?.authChangePassword));
  }

  getResetPasswordLink(userId: string) {
    return this.authGetResetPasswordLinkGQL
      .fetch({ userId })
      .pipe(map((response) => response.data.authGetResetPasswordLink));
  }

  setUserPassword(userId: string, newPassword: string, repeatPassword: string) {
    return this.authSetUserPasswordGQL
      .mutate({
        userId,
        newPassword,
        repeatPassword,
      })
      .pipe(map((response) => response.data));
  }
}
