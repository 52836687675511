import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingDialogService {

  _isLoadingSubject = new BehaviorSubject<boolean>(false);
  _isLoading$: Observable<boolean>

  private message = 'Loading...'

  constructor() {
    this._isLoading$ = this._isLoadingSubject.asObservable();
   }

  get isLoading$() {
    return this._isLoading$;
  }

  show() {
    this._isLoadingSubject.next(true);
  }

  hide() {
    this._isLoadingSubject.next(false);
  }

  setMessage(msg: string) {
    this.message = msg;
  }

  getMessage() {
    return this.message
  }
}