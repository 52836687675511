import {Injectable} from '@angular/core';
import {Store} from "@ngxs/store";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {AsyncInitializationService} from "../services/async-initialization.service";

@Injectable({
  providedIn: 'root'
})
export class AsyncInitializationGuardService {

  constructor(private store: Store, private router: Router, private asyncInitializationService: AsyncInitializationService) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      let allTasksComplete = await this.asyncInitializationService.awaitTasksCompletion();
      if (!allTasksComplete) {
        this.router.navigate(['/error']);
        return false;
      }
      return true;
    } catch (error) {
      this.router.navigate(['/error']);
      return false;
    }
  }
}
