import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from "@apollo/client/link/context";
import { environment } from "../../../environments/environment";
import { onError } from "@apollo/client/link/error";
import { Store } from "@ngxs/store";
import { AuthState } from "../../state/auth/auth.state";
import { StorageState } from 'src/app/state/storage/storage.state';
import { GraphQLError } from 'graphql/error';
import { capitalize } from 'lodash';
import { ErrorCodeService } from "../services/error-code.service";
import { CustomErrorCode } from "./generated/graphql";

// const uri = 'https://api.stage-v3.standortmonitor.net/graphql'; // <-- add the URL of the GraphQL server here

export function createApollo(httpLink: HttpLink, store: Store, errorCodeService: ErrorCodeService): ApolloClientOptions<any> {
  /*const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));*/

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.debug(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }
    if (networkError) console.debug(`[Network error]: ${networkError}`);
  });

  const formatErrorLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      // Flatten the error to give detailed error message
      // Note: for admin only
      /*if (response.errors && store.selectSnapshot(AuthState.isUserAdmin) && errorCodeService.getErrorCode(response.errors[0] as GraphQLError) == CustomErrorCode.UnlistedError) {
        const errMessage = (response.errors[0]?.extensions['response'] as any)
          ?.message;

        if (errMessage && Array.isArray(errMessage[0])) {
          response.errors[0].message = capitalize(errMessage);
        }
      } else*/
      if (response.errors) {
        const errorCode = errorCodeService.getErrorCode(response.errors[0] as GraphQLError);
        let message = errorCodeService.getErrorMessage(errorCode);

        if (store.selectSnapshot(AuthState.isUserAdmin)) {

          let errMessage = (response.errors[0]?.extensions['response'] as any)?.message;

          if (!errMessage) {
            errMessage = response.errors[0]?.message;
          }

          if (errMessage) {
            message = message + ' >> ' + capitalize(errMessage);
          }
        }

        response.errors[0].message = message;
      }
      return response;
    });
  });

  const auth = setContext((operation, context) => {

    const token = store.selectSnapshot(AuthState.accessToken) ?? store.selectSnapshot(StorageState.accessToken);

    if ('headers' in context && 'Authorization' in context.headers) return context;
    if (!token) return context;

    return { ...context, headers: { ...context.headers, Authorization: `Bearer ${token}` } };
  });

  const link = ApolloLink.from([formatErrorLink, errorLink, auth, httpLink.create({ uri: environment.serverUrl + environment.graphQLEndpoint })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache
  }
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Store, ErrorCodeService],
    },
  ],
})

export class GraphQLModule {

}
