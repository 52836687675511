import { NgModule } from '@angular/core';
import { SearchResultComponent } from './search-result/search-result.component';
import { SearchResultRoutingModule } from './search-result-routing.module';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ResultBreadcrumbComponent } from './components/result-breadcrumb/result-breadcrumb.component';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { ResultItemComponent } from './components/result-item/result-item.component';
import { FrequentSearchComponent } from './components/frequent-search/frequent-search.component';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    SearchResultComponent,
    SearchBarComponent,
    ResultItemComponent,
    ResultBreadcrumbComponent,
    FrequentSearchComponent,
  ],
  providers: [],
  imports: [
    SearchResultRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BreadcrumbModule,
    DialogModule,
    InputTextModule,
    SkeletonModule,
    DropdownModule,
  ],
  exports: [SearchBarComponent, FrequentSearchComponent],
})
export class SearchResultModule {
  constructor() {}
}
