import { Action, State, StateContext } from "@ngxs/store";
import { ROUTER_STATE } from "../index";
import { Router } from "@angular/router";
import { Navigate } from "./router.actions";
import { Injectable } from "@angular/core";

@State<string>({
  name: ROUTER_STATE,
  defaults: ""
})
@Injectable()
export class RouterState {

  constructor(private router: Router) {
  }

  @Action(Navigate)
  async changeRoute(context: StateContext<string>, { path }: Navigate) {
    await this.router.navigate([path]);
    context.setState(path);
  }
}
