import { Component, OnInit } from '@angular/core';
import {UserService} from "../shared/services/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {

  }

}
