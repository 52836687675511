<div class="layout-sidebar" style="border-right: unset !important;">
  <div class="px-3 mt-3">
    <p-dropdown
      *ngIf="!(productsLoading$ | async) else productsLoading"
      [options]="(accessibleProducts$ | async)!"
      [ngModel]="selectedProduct$ | async"
      (onChange)="setProduct($event)"
      optionLabel="name"
      placeholder="Produkt wählen"
      styleClass="w-full"
      scrollHeight="250px"
    ></p-dropdown>
  </div>

  <ng-template #productsLoading>
    <p-skeleton height="42px"></p-skeleton>
  </ng-template>

  <div class="layout-menu-container">
    <app-menu></app-menu>
  </div>
</div>
