import { CreateUserForOwnOrgInput, OrganisationEditOwnInput } from "../../shared/graphql/generated/graphql";

export namespace Org {
  export class UpdateCurrentOrgProfile {
    static readonly type = '[Org] UpdateProfile';
    constructor(public organisationUpdateOwnInput: OrganisationEditOwnInput) { }
  }

  export class LoadCurrentOrg {
    static readonly type = '[Org] LoadOrg';
    constructor(public orgId: string) { }
  }

  export class CreateOrgUser {
    static readonly type = '[Org] CreateOrgUser';
    constructor(public orgUserCreateInput: CreateUserForOwnOrgInput) { }
  }

  export class RemoveOrgUser {
    static readonly type = '[Org] RemoveOrgUser';
    constructor(public userId: string) { }
  }
}
