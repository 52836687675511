import jwtDecode, {JwtPayload} from "jwt-decode";
import {IAccessibleDataset} from "../../shared/model/accessible-dataset.interface";

export function isTokenValid(token: string = ''): boolean {
  if (token === '') return false

  const decoded = jwtDecode<JwtPayload>(token);
  if (!decoded.exp) return false;

  return decoded.exp * 1000 >= new Date().getTime()
}

// export function getUserClaims(accessToken?: string): string[] {
//   if (!accessToken) return [];

//   const payload = jwtDecode<JwtPayload & { claims: string[] }>(accessToken);
//   return payload.claims;
// }

// export function getAccessibleDatasetsFromToken(accessToken?: string): Map<string, IAccessibleDataset> {
//   if (!accessToken) return new Map<string, IAccessibleDataset>();

//   const payload = jwtDecode<JwtPayload & { accessible_datasets: Map<string, any> }>(accessToken);
//   // get map key into map value and convert to IAccessibleDataset
//   const accessibleDatasets = new Map<string, IAccessibleDataset>();
//   Object.entries(payload.accessible_datasets).forEach(([key, value]) => {
//     const tmp = {...value, id: key} as IAccessibleDataset;
//     accessibleDatasets.set(key, tmp);
//   });
//   return accessibleDatasets;
// }
