import { CreateProductInput, Product, UpdateProductInput } from "../../shared/graphql/generated/graphql";

export namespace Products {

  export class Initialize {
    static readonly type = '[Product] Initialize';
  }

  export class SelectProduct {
    static readonly type = '[Product] SelectProduct';
    constructor(public product: Product) { }
  }

  export class SelectProductById {
    static readonly type = '[Product] SelectProductById';
    constructor(public productId: number, public redirect: boolean = true) { }
  }

  export class UnauthorizedProductSelectionAttempt {
    static readonly type = '[Product] UnauthorizedProductSelectionAttempt';
    constructor(public productId: number) { }
  }

  export class PreSelectProductByIdOnLoad {
    static readonly type = '[Product] PreSelectProductByIdOnLoad';
  }

  export class PreSelectProductByIdOnLoadFailed {
    static readonly type = '[Product] PreSelectProductByIdOnLoadFailed';
  }

  export class SelectProductByIdFromRoute {
    static readonly type = '[Product] SelectProductByIdFromRoute';
    constructor(public productId: number) { }
  }

  export class ClearSelectedProduct {
    static readonly type = '[Product] ClearSelectedProduct';
  }

  export class LoadProducts {
    static readonly type = '[Product] LoadProducts';
  }

  export class CreateProduct {
    static readonly type = '[Product] CreateProduct';
    constructor(public createProductInput: CreateProductInput) { }
  }

  export class UpdateProduct {
    static readonly type = '[Product] UpdateProduct';
    constructor(public updateProductInput: UpdateProductInput) { }
  }

  export class ToggleActive {
    static readonly type = '[Product] ToggleActive';
    constructor(public id: number | null) { }
  }

  export class RemoveProduct {
    static readonly type = '[Product] RemoveProduct';
    constructor(public productId: number) { }
  }
}
