<div class="layout-topbar" style="background-color: var(--primary-color)">
  <div class="topbar-start">
    <span [routerLink]="['/']" class="app-logo cursor-pointer">
      <div class="flex align-items-center">
        <img
          alt="image"
          src="/assets/images/stamo-icon-white.png"
          style="height: 25px"
        />
        <div class="flex flex-column ml-1 py-0 gap-0">
          <span class="font-bold mb-0 text-lg" style="letter-spacing: 1px">
            STANDORTMONITOR
          </span>
          <ng-container *ngIf="selectedProduct$ | async as selectedProduct;">
            <span class="mt-0 text-xs">{{ selectedProduct.name | uppercase }}</span>
          </ng-container>
          <ng-container *ngIf="fetching$ | async">
            <p-skeleton height="0.75rem" width="100%"></p-skeleton>
          </ng-container>
        </div>
      </div>
    </span>

    <ul class="topbar-menu pl-3">
      <button
        #menubutton
        (click)="onMenuButtonClick()"
        class="topbar-menubutton p-link p-trigger"
        type="button"
      >
        <i class="pi pi-bars"></i>
      </button>
      <li class="topbar-search" *ngIf="!hideSearchbar">
        <!-- <span class="p-input-icon-right">
                    <i class="pi pi-search pr-2"></i>
                    <input class="w-30rem" pInputText placeholder="BITTE HIER SUCHBEGRIFF EINGEBEN..." type="text">
                </span> -->
        <div class="w-30rem">
          <app-search-bar [withDialog]="true" [full]="false" />
        </div>
      </li>
    </ul>

    <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
  </div>

  <div class="topbar-end">
    <ul class="topbar-menu">
      <!-- <li class="topbar-search"> -->
      <!-- <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            type="text"
            pInputText
            placeholder="Search"
            class="w-12rem sm:w-full"
          />
        </span> -->
      <!-- </li> -->
      <li *ngIf="isUserAdmin$ | async" class="ml-4">
        <button
          (click)="onAdminClick()"
          class="p-button-rounded"
          icon="pi pi-wrench"
          label="Administration"
          pButton
          style="height: 2.6rem"
          type="button"
        ></button>
      </li>
      <li (click)="onProfileButtonClick()" class="topbar-profile p-link">
        <!--<button (click)="onProfileButtonClick()" class="p-link" type="button">
              <img alt="Profile" src="assets/layout/images/avatar.png"/>
            </button>-->
        <!--<img alt="Profile" src="assets/layout/images/avatar.png"/>-->
        <span class="topbar-profile-hover-container">
          <!--  + user.lastName.charAt(0) -->
          <ng-container *ngIf="profile$ | async as user; else loadingAvatar">
            <p-avatar
              [label]="user.firstName.charAt(0) | uppercase"
              [style]="{ 'background-color': '#1C1D1F', color: '#FFFDF9' }"
              shape="circle"
              size="xlarge"
              styleClass="border-circle"
            ></p-avatar>
          </ng-container>
          <ng-template #loadingAvatar>
            <p-skeleton shape="circle" size="4.1rem" styleClass=""></p-skeleton>
          </ng-template>
          <div class="flex align-items-center pr-2 pl-1">
            <div class="flex flex-column ml-1 py-0 gap-0 uppercase">
              <ng-container
                *ngIf="profile$ | async as user; else loadingUserName"
              >
                <span
                  class="font-bold mb-0 upper-case"
                  style="font-size: 1.075rem"
                >
                  {{ user.firstName }} {{ user.lastName }}
                </span>
                <span
                  *ngIf="user.organisation"
                  class="mt-0"
                  style="font-size: 12px"
                  [attr.title]="user.organisation.name"
                >
                  {{
                    user.organisation.name.length > 24
                      ? user.organisation.name.slice(0,24) + '...'
                      : user.organisation.name
                  }}
                </span>
              </ng-container>

              <ng-template #loadingUserName>
                <p-skeleton styleClass="mb-1" width="9rem"></p-skeleton>
                <p-skeleton styleClass="" width="80%"></p-skeleton>
              </ng-template>
            </div>
          </div>
        </span>
      </li>
    </ul>
  </div>
</div>
