<section class="w-full">
  <div class="flex w-full align-items-center justify-content-between px-1">
    <div *ngIf="full; then withButton; else withIcon"></div>
  </div>
</section>

<p-dialog
  [(visible)]="modalVisible"
  [dismissableMask]="true"
  [modal]="true"
  [showHeader]="false"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '75vw', 'max-width': '950px' }"
  contentStyleClass="border-round-top p-0"
  appendTo="body"
  (onShow)="inputSearch.focus()"
  (onHide)="modalVisible = false"
  *ngIf="withDialog"
  #searchDialog
>
  <div
    class="flex w-full surface-section align-items-center justify-content-between px-1"
  >
    <form
      [formGroup]="searchFormGroup"
      class="relative w-full"
      (ngSubmit)="onSubmit()"
    >
      <input
        type="text"
        #inputSearch
        pInputText
        placeholder="BITTE HIER SUCHBEGRIFF EINGEBEN..."
        formControlName="searchText"
        class="w-full border-none shadow-none outline-none"
      />

      <div
        class="absolute flex justify-content-center align-items-center z-3 right-0 top-0 h-full pr-1"
      >
        <button class="p-button p-button-rounded p-button-sm">SUCHEN</button>
      </div>
    </form>
  </div>
  <div class="border-y-1 surface-border p-3">
    <h2 class="font-semibold text-xs text-500 mb-2 mt-0 px-1">ERGEBNISSE</h2>

    <p
      *ngIf="searchService.searchResults.length === 0 && searchText"
      class="p-1"
    >
      Keine Ergebnisse
    </p>

    <ul
      class="list-none m-0 p-0 w-full"
      *ngIf="searchService.searchResults.length > 0"
    >
      <li
        *ngFor="let result of searchService.searchResults.slice(0, 4)"
        class="select-none p-2 font-medium text-base cursor-pointer border-round-lg hover:surface-hover w-full"
      >
        <app-search-result-item
          (click)="onResultClick(result)"
          [result]="result"
        />
      </li>

      <li
        *ngIf="searchService.searchResults.length >= 4"
        class="select-none p-2 font-medium text-base cursor-pointer border-round-lg hover:surface-hover w-full flex justify-content-between align-items-center"
      >
        <a
          routerLink="/search"
          [queryParams]="{
            productName: getSelectedProduct()?.name,
            tag: searchText
          }"
          (click)="modalVisible = false"
          class="p-0"
        >
          Alle Ergebnisse für "{{ searchText }}" anzeigen
        </a>

        <i
          class="pi pi-chevron-right align-items-center text-primary-500"
          style="font-size: 0.75rem"
        ></i>
      </li>
    </ul>
  </div>

  <div class="p-3">
    <ul
      *ngIf="!(productsLoading$ | async); else productsLoading"
      class="list-none m-0 p-0"
    >
      <h2 class="font-semibold text-xs text-500 mb-2 mt-0 px-1">
        AUSGEWÄHLTES PRODUKT
      </h2>
      <li
        *ngFor="let product of accessibleProducts$ | async; let i = index"
        class="select-none p-2 font-medium text-base cursor-pointer border-round-lg hover:surface-hover hover:text-primary flex justify-content-between align-items-center"
        (click)="setProduct(product.id)"
        [ngClass]="{ 'text-primary': selectedProductId === product.id }"
      >
        {{ product.name }}
      </li>
    </ul>
  </div>
</p-dialog>

<ng-template #productsLoading>
  <p-skeleton height="150px"></p-skeleton>
</ng-template>

<ng-template #withButton>
  <form
    [formGroup]="searchFormGroup"
    class="relative w-full"
    (ngSubmit)="onSubmit()"
  >
    <input
      type="text"
      pInputText
      formControlName="searchText"
      placeholder="BITTE HIER SUCHBEGRIFF EINGEBEN..."
      class="w-full border-none shadow-none outline-none border-round-3xl"
      (click)="withDialog && (modalVisible = true)"
      (focus)="withDialog && (modalVisible = true)"
    />

    <div
      *ngIf="full"
      class="absolute flex justify-content-center align-items-center z-3 top-0 h-full"
      [ngStyle]="{
        right: '-0.75rem'
      }"
    >
      <div class="block">
        <p-dropdown
          (onChange)="setProduct($event.value?.id)"
          *ngIf="!(productsLoading$ | async); else productsLoading"
          [ngModel]="selectedProduct$ | async"
          [ngModelOptions]="{standalone: true}"
          [options]="(accessibleProducts$ | async)!"
          optionLabel="name"
          styleClass="border-primary"
        ></p-dropdown>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #withIcon>
  <form
    [formGroup]="searchFormGroup"
    class="relative w-full"
    (ngSubmit)="onSubmit()"
  >
    <div class="p-input-icon-left w-full">
      <i class="pi pi-search text-500"></i>
      <input
        type="text"
        pInputText
        formControlName="searchText"
        placeholder="BITTE HIER SUCHBEGRIFF EINGEBEN..."
        class="w-full border-none shadow-none outline-none border-round-3xl"
        (click)="withDialog && (modalVisible = true)"
        (focus)="withDialog && (modalVisible = true)"
      />
    </div>
  </form>
</ng-template>

<ng-template #productsLoading>
  <p-skeleton height="2rem"></p-skeleton>
</ng-template>
