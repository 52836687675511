import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BreadcrumbItemClickEvent } from 'primeng/breadcrumb';

@Component({
  selector: 'app-search-result-breadcrumb',
  templateUrl: './result-breadcrumb.component.html',
  styleUrls: ['./result-breadcrumb.component.scss'],
})
export class ResultBreadcrumbComponent implements OnInit {
  @Input() items: MenuItem[] = [];

  handleItemClick(e: BreadcrumbItemClickEvent) {}

  ngOnInit() {}
}
