import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {environment} from "../../../environments/environment";
import {Store} from "@ngxs/store";
import {AuthState} from "../../state/auth/auth.state";
import {StorageState} from "../../state/storage/storage.state";
import * as d3 from "d3";
import {DSVRowArray} from "d3";

@Injectable({
  providedIn: 'root'
})
export class DatasetsService {

  _datasetCache: Map<string, DSVRowArray> = new Map<string, DSVRowArray>();

  constructor(private http: HttpClient, private authService: AuthService, private store: Store) {
  }


  _filterDatasetMock(dataset: DSVRowArray, filters: Map<string, any>): DSVRowArray {
    return <DSVRowArray<string>>dataset.filter(row => {
      if (filters.has('methodology')) {
        if (row['methodology_id'] !== filters.get('methodology')) {
          return false;
        }
      }
      return true;
    });
  }

  async getDataset(datasetId: string, filters: Map<string, any>): Promise<DSVRowArray> {

    let path = '/datasets/' + datasetId;

    const filtersQuery = [...filters.entries()].map(([key, value]) => `${key}==${value}`).join(';');

    if (filters.size > 0 && filtersQuery.length > 0) {
      path += '?filter=' + filtersQuery;
    }

    if (this._datasetCache.has(path)) {
      console.debug(`[DatasetService] Getting ${path} from cache`);
      return this._datasetCache.get(path)!;
    }
    const token = this.store.selectSnapshot(AuthState.accessToken) ?? this.store.selectSnapshot(StorageState.accessToken);

    console.debug(`[DatasetService] Getting ${path} from remote...`);
    const fetchStartTime = Date.now();
    const data = await d3.csv(environment.serverUrl + path, {
      headers: {
        'Content-Type': 'text/csv',
        'Authorization': `Bearer ${token}`
      }
    });
    this._datasetCache.set(path, data);
    const fetchEndTime = Date.now();
    console.debug(`[DatasetService] Fetched ${path} from remote (${fetchEndTime - fetchStartTime}ms)`);
    return data;
  }
}
