import {Action, Selector, State, StateContext} from "@ngxs/store";
import {SettingsStateModel} from "./settings.model";
import {Injectable} from "@angular/core";
import {SettingsAction} from "./settings.action";
import {tap} from "rxjs";
import {MessageService} from "primeng/api";
import {SettingService} from "src/app/shared/services/setting.service";
import {SETTING_STATE} from "..";
import {FilterSetting} from "src/app/shared/graphql/generated/graphql";
import {
  AsyncInitializationService,
  AsyncInitializationTaskState
} from "../../shared/services/async-initialization.service";

@State<SettingsStateModel>({
  name: SETTING_STATE,
  defaults: {
    fetching: false,
    filterSettings: []
  }
})
@Injectable()
export class SettingsState {

  constructor(
    private settingService: SettingService,
    private messageService: MessageService,
    private asyncInitializationService: AsyncInitializationService
  ) { }

  @Selector()
  static filterSettings(state: SettingsStateModel) {
    return state.filterSettings;
  }

  @Action(SettingsAction.LoadCurrentUserFilterSettings)
  loadSettings({ patchState, getState, dispatch }: StateContext<SettingsStateModel>) {
    if (getState().fetching) {
      return;
    }
    patchState({ fetching: true })
    this.asyncInitializationService.updateTaskState('user-filter-settings', AsyncInitializationTaskState.PENDING);
    return this.settingService.fetchFilterSettings().pipe(
        tap({
              next: (settings) => {
                patchState({
                  fetching: false,
                  filterSettings: settings as FilterSetting[]
                });
                this.asyncInitializationService.updateTaskState('user-filter-settings', AsyncInitializationTaskState.SUCCESS);
              },
              error: (err) => {
                console.error('[SettingsState] Error while fetching filter settings', err);
                patchState({
                  fetching: false,
                });
                this.asyncInitializationService.updateTaskState('user-filter-settings', AsyncInitializationTaskState.ERROR);
              }
      }
        ));
  }

  @Action(SettingsAction.SetProductFilterSetting)
  setProductFilterSetting(ctx: StateContext<SettingsStateModel>, action: SettingsAction.SetProductFilterSetting) {

    const filterSettingsInput = action.setFilterSettingInput;
    if (filterSettingsInput.methodologyId == null || isNaN(filterSettingsInput.methodologyId)) {
      filterSettingsInput.submarketId = null;
    }

    return this.settingService.setFilterSetting(action.setFilterSettingInput).pipe(
      tap({
        next: (filterSetting) => {
          const state = ctx.getState()

          // Add if not exist, otherwise update
          const existingSetting = state.filterSettings.find(setting => setting.id === filterSetting.id)
          if (!existingSetting) {
            ctx.patchState({
              filterSettings: [...state.filterSettings, filterSetting]
            })
          } else {
            ctx.patchState({
              filterSettings: state.filterSettings.map(setting => setting.id === filterSetting.id ? filterSetting : setting) as FilterSetting[]
            })
          }

          this.messageService.add({
            severity: 'success',
            summary: 'Gespeichert',
            detail: `Voreinstellungen für "${filterSetting.product.name}" erfolgreich gespeichert.`
          })
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: 'Voreinstellungen konnten nicht gespeichert werden.'
          })
        }
      })
    )
  }
}
