export namespace LocalDatasetsAction {

  export class Initialize {
    static readonly type = '[LocalDatasets] Initialize';
  }

  export class LoadWzClassesFromRemote {
    static readonly type = '[LocalDatasets] Load WZ Classes';
  }

  export class LoadSubMarketsFromRemote {
    static readonly type = '[LocalDatasets] Load Sub Markets';
  }

  export class LoadIndustrySubMarketsFromRemote {
    static readonly type = '[LocalDatasets] Load Industry Sub Markets';
  }

  export class LoadMethodologiesFromRemote {
    static readonly type = '[LocalDatasets] Load Methodologies';
  }

  export class LoadRegionalAssociationsFromRemote {
    static readonly type = '[LocalDatasets] Load Regional Associations';
  }

  export class LoadAdministrativeDistrictsFromRemote {
    static readonly type = '[LocalDatasets] Load Administrative Districts';
  }

  export class LoadDistrictsFromRemote {
    static readonly type = '[LocalDatasets] Load Districts';
  }

}
