import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from "@angular/router";
import {Store} from "@ngxs/store";
import {AuthState} from "../../state/auth/auth.state";

@Injectable({
  providedIn: 'root'
})
export class ClaimGuardService {

  constructor(private store: Store, private router: Router) {
  }


  canActivate(route: ActivatedRouteSnapshot) {
    if (route.data['requiredClaims'] == null || route.data['requiredClaims'].length == 0) return true;
    const canActivate = this.store.selectSnapshot(AuthState.hasClaims(route.data['requiredClaims']));
    if (!canActivate) this.router.navigate(['/']);
    return canActivate;
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.canActivate(route);
  }
}
