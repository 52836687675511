import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import {generalMenuItemsBottom, generalMenuItemsTop, MenuItem,} from "./app.menu.items";
import {Product} from "../shared/graphql/generated/graphql";
import {map, Observable} from "rxjs";
import {Select} from "@ngxs/store";
import {ProductsState} from "../state/products/products.state";

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  // model: MenuItem[] = [];
  @Select(ProductsState.accessibleProducts) accessibleProducts$!: Observable<Product[] | undefined>;
  @Select(ProductsState.productsLoading) productsLoading$!: Observable<boolean>;
  @Select(ProductsState.selectedProduct) selectedProduct$!: Observable<Product | undefined>;
  @Select(ProductsState.selectedProductMenuModel) selectedProductMenuModel$!: Observable<MenuItem[]>;

  menuModel$: Observable<MenuItem[]> = this.selectedProductMenuModel$.pipe(
    map((selectedProductMenuModel) => {
      return [...generalMenuItemsTop, ...selectedProductMenuModel, ...generalMenuItemsBottom];
    })
  );

  constructor() {

  }

  ngOnInit() {

  }
}
