import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { News, NewsListGQL } from '../graphql/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private newsListGQL: NewsListGQL) {}

  getNewsList() {
    return this.newsListGQL
      .fetch()
      .pipe(map((response) => response.data.newsList as News[]));
  }
}
