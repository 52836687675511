import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { map, Subscription, switchMap } from 'rxjs';
import { SearchTag } from 'src/app/dashboards/shared/interfaces/SearchTag';
import { LocalDatasetsService } from 'src/app/shared/services/local-datasets.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { Select, Store } from '@ngxs/store';
import { ProductsState } from 'src/app/state/products/products.state';
import { Product } from 'src/app/shared/graphql/generated/graphql';
import { Observable } from '@apollo/client/core';
import { Products } from 'src/app/state/products/products.action';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @ViewChild('inputSearch') inputSearch!: ElementRef;
  @ViewChild('searchDialog') searchDialog!: ElementRef;

  @Input() withDialog = false;
  @Input() withFrequentSearch = true;
  @Input() full = true;

  @Select(ProductsState.accessibleProducts) accessibleProducts$!: Observable<
    Product[] | undefined
  >;
  @Select(ProductsState.productsLoading) productsLoading$!: Observable<boolean>;
  @Select(ProductsState.selectedProduct) selectedProduct$!: Observable<
    Product | undefined
  >;
  @Select(ProductsState.products) products$!: Observable<Product[]>;

  searchFormGroup = this.fb.group({
    searchText: [''],
  });

  modalVisible = false;
  routeSubscription!: Subscription;

  constructor(
    private fb: FormBuilder,
    public searchService: SearchService,
    private localDatasetsService: LocalDatasetsService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.searchFormGroup?.get('searchText')?.valueChanges.subscribe((value) => {
      this.searchService.setSearchText(value || '');
      this.searchService.onSearch(value || '');
    });
  }

  get searchText() {
    return this.searchFormGroup.get('searchText')?.value;
  }

  setProduct(id: number) {
    this.store.dispatch(new Products.SelectProductById(id));
    this.searchService.onSearch(this.searchText || '');
    this.inputSearch.nativeElement.focus();
  }

  getSelectedProduct() {
    return this.store.selectSnapshot(ProductsState.selectedProduct);
  }

  getProducts() {
    return this.store.select(ProductsState.products);
  }

  get selectedProductId() {
    return this.getSelectedProduct()?.id;
  }

  onSubmit() {
    this.router.navigate(['/search'], {
      queryParams: {
        productName: this.getSelectedProduct()?.name,
        tag: this.searchText,
      },
    });
    this.modalVisible = false;
  }

  onResultClick(result: SearchTag) {
    this.modalVisible = false;
  }

  ngOnInit(): void {
    this.localDatasetsService.loadSearchTags();

    this.routeSubscription = this.route.queryParams
      .pipe(
        switchMap((queryParams) => {
          return this.getProducts().pipe(
            map((products) => ({ products, queryParams }))
          );
        })
      )
      .subscribe(({ queryParams, products }) => {
        if (queryParams['tag'] && queryParams['productName']) {
          const { productName, tag } = queryParams;

          const product = products.find(
            (product) => product.name === productName
          );

          if (product) {
            if (product?.id !== this.getSelectedProduct()?.id) {
              this.store.dispatch(
                new Products.SelectProductById(product?.id as number)
              );
            }
            this.searchFormGroup?.get('searchText')?.setValue(tag);
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
