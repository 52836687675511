import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-frequent-search',
  templateUrl: './frequent-search.component.html',
  styleUrls: ['./frequent-search.component.scss'],
})
export class FrequentSearchComponent implements OnInit {
  constructor(private router: Router) {}

  @Input() selectedProductName!: string | undefined;

  mostFrequentSearches = [
    { id: 1, query: 'Umsatz' },
    { id: 2, query: 'Vergleich Kreisebene' },
    { id: 3, query: 'Prognose WZ-Klassen' },
    { id: 4, query: 'Prognose Filmwirtschaft' },
  ];

  toSearchPageWithQuery(query: string) {
    this.router.navigate(['/search'], {
      queryParams: {
        productName: this.selectedProductName,
        tag: query,
      },
    });
  }

  ngOnInit(): void {}
}
