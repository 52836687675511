import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {
  Organisation,
  OrganisationGQL,
  OrganisationEditOwnInput,
  CreateUserForOwnOrgInput,
  UserCreateForOwnOrganisationGQL,
  User,
  OrganisationUpdateOwnGQL,
  OrganisationsGQL,
  CreateOrganisationInput,
  OrganisationCreateGQL,
  UpdateOrganisationInput,
  OrganisationUpdateGQL,
  OrganisationRemoveGQL,
  OrganisationAddProductGQL,
  OrganisationAddSpecialAnalysisGQL,
  OrganisationRemoveProductGQL,
  OrganisationRemoveSpecialAnalysisGQL,
  OrganisationRemoveUserGQL
} from '../graphql/generated/graphql';


@Injectable({
  providedIn: 'root',
})
export class OrgService {

  constructor(
    private orgGQL: OrganisationGQL,
    private organisationUpdateOwnGQL: OrganisationUpdateOwnGQL,
    private orgUserCreateGQL: UserCreateForOwnOrganisationGQL,
    private orgsGQL: OrganisationsGQL,
    private orgCreateGQL: OrganisationCreateGQL,
    private orgUpdateGQL: OrganisationUpdateGQL,
    private orgRemoveGQL: OrganisationRemoveGQL,
    private addProductGQL: OrganisationAddProductGQL,
    private removeProductGQL: OrganisationRemoveProductGQL,
    private removeUserGQL: OrganisationRemoveUserGQL,
    private addSpecialAnalysisGQL: OrganisationAddSpecialAnalysisGQL,
    private removeSpecialAnalysisGQL: OrganisationRemoveSpecialAnalysisGQL
  ) {
  }

  getOrg(organisationId: string) {
    return this.orgGQL.watch({ organisationId }).valueChanges.pipe(map((res) => res.data.organisation as Organisation));
  }

  updateOwnOrg(organisationEditOwnInput: OrganisationEditOwnInput) {
    return this.organisationUpdateOwnGQL.mutate({ organisationEditOwnInput }).pipe(
      map((response) => response.data?.organisationUpdateOwn as Organisation)
    );
  }

  createOrgUser(userCreateForOwnOrgInput: CreateUserForOwnOrgInput) {
    return this.orgUserCreateGQL.mutate({ userCreateForOwnOrgInput }).pipe(
      map((response) => response.data?.userCreateForOwnOrganisation as User)
    )
  }

  removeOrgUser(userRemoveId: string) {
    return this.removeUserGQL
      .mutate({ userRemoveId })
      .pipe(map((result) => result.data?.userRemove as User))
  }

  loadOrgs() {
    return this.orgsGQL.watch().valueChanges.pipe(
      map((res) => res.data.organisations as Organisation[])
    )
  }

  createOrg(organisationCreateInput: CreateOrganisationInput) {
    return this.orgCreateGQL
      .mutate({ organisationCreateInput })
      .pipe(map((response) => response.data?.organisationCreate as Organisation))
  }

  updateOrg(organisationUpdateInput: UpdateOrganisationInput) {
    return this.orgUpdateGQL
      .mutate({ organisationUpdateInput })
      .pipe(map((result) => result.data?.organisationUpdate as Organisation))
  }

  removeOrg(organisationRemoveId: string) {
    return this.orgRemoveGQL
      .mutate({ organisationRemoveId })
      .pipe(map((result) => result.data?.organisationRemove as Organisation))
  }

  addProduct(organisationId: string, productId: number) {
    return this.addProductGQL
      .mutate({ organisationId, productId })
      .pipe(map((response) => response.data?.organisationAddProduct as Organisation))
  }

  removeProduct(organisationId: string, productId: number) {
    return this.removeProductGQL
      .mutate({ organisationId, productId })
      .pipe(map((response) => response.data?.organisationRemoveProduct as Organisation))
  }

  addSpecialAnalysis(organisationId: string, specialAnalysisId: number) {
    return this.addSpecialAnalysisGQL
      .mutate({ organisationId, specialAnalysisId })
      .pipe(map((response) => response.data?.organisationAddSpecialAnalysis as Organisation))
  }

  removeSpecialAnalysis(organisationId: string, specialAnalysisId: number) {
    return this.removeSpecialAnalysisGQL
      .mutate({ organisationId, specialAnalysisId })
      .pipe(map((response) => response.data?.organisationRemoveSpecialAnalysis as Organisation))
  }


}
