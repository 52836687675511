import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs';
import {
  CreateUserInput,
  UpdateUserInput,
  User,
} from 'src/app/shared/graphql/generated/graphql';
import { UserService } from 'src/app/shared/services/user.service';
import { nullifyEmptyString } from 'src/app/shared/utils/nullifyEmptyString';
import { ADMIN_USER_STATE } from 'src/app/state';
import { AdminUserAction } from './admin-user.action';
import { AdminUserStateModel } from './admin-user.model';
import { AuthService } from 'src/app/shared/services/auth.service';

@State<AdminUserStateModel>({
  name: ADMIN_USER_STATE,
  defaults: {
    fetching: false,
    users: [],
  },
})
@Injectable()
export class AdminUserState {
  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private authService: AuthService
  ) {}

  @Selector()
  static fetching(state: AdminUserStateModel) {
    return state.fetching;
  }

  @Selector()
  static users(state: AdminUserStateModel) {
    return state.users;
  }

  @Selector()
  static userTable(state: AdminUserStateModel) {
    return state.users.map(({ __typename, ...user }: User) => ({
      ...user,
      orgName: user.organisation?.name ?? '---',
      role: user.isAdmin ? 'Admin' : user.isOrgAdmin ? 'Org. Admin' : 'User',
      expiry: user.organisation?.licenseExpiresAt,
    }));
  }

  @Selector()
  static userById(state: AdminUserStateModel) {
    return (userId: string | null) =>
      state.users.find((user) => user.id === userId);
  }

  static userById2(userId: string) {
    return createSelector([AdminUserState], (state: AdminUserStateModel) => {
      return state.users.find((user) => user.id === userId);
    });
  }

  @Selector()
  static userByOrgId(state: AdminUserStateModel) {
    return (orgId: string | null) =>
      state.users.filter((user) => user.organisationId === orgId);
  }

  @Action(AdminUserAction.LoadUsers)
  loadUsers(
    ctx: StateContext<AdminUserStateModel>,
    action: AdminUserAction.LoadUsers
  ) {
    ctx.patchState({ fetching: true });
    return this.userService.getUsers().pipe(
      tap((users) => {
        ctx.patchState({
          fetching: false,
          users: [...users],
        });
      })
    );
  }

  @Action(AdminUserAction.CreateUser)
  createUser(
    ctx: StateContext<AdminUserStateModel>,
    action: AdminUserAction.CreateUser
  ) {
    const payload = nullifyEmptyString(
      action.createUserInput
    ) as CreateUserInput;

    return this.userService.createUser(payload).pipe(
      tap({
        next: (newUser) => {
          const state = ctx.getState();
          ctx.patchState({
            users: [...state.users, newUser],
          });
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Nutzer erfolgreich erstellt.',
          });
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: 'Nutzer konnte nicht gelöscht werden.',
          });
        },
      })
    );
  }

  @Action(AdminUserAction.UpdateUser)
  updateUser(
    ctx: StateContext<AdminUserStateModel>,
    action: AdminUserAction.UpdateUser
  ) {
    const payload = nullifyEmptyString(
      action.updateUserInput
    ) as UpdateUserInput;

    return this.userService.updateUser(payload).pipe(
      tap((updatedUser) => {
        const state = ctx.getState();

        ctx.patchState({
          users: state.users.map((user) =>
            user.id === updatedUser.id ? updatedUser : user
          ),
        });

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'User updated successfully.',
        });
      })
    );
  }

  @Action(AdminUserAction.ToggleActive)
  toggleActive(
    ctx: StateContext<AdminUserStateModel>,
    action: AdminUserAction.ToggleActive
  ) {
    const user = ctx.getState().users.find((user) => user.id === action.userId);

    const payload: UpdateUserInput = {
      id: user!.id,
      isActivated: !user!.isActivated,
      isLocked: user!.isLocked,
      organisationId: user!.organisationId,
    };

    return this.userService.updateUser(payload).pipe(
      tap((updatedUser) => {
        const state = ctx.getState();

        ctx.patchState({
          users: state.users.map((user) =>
            user.id === updatedUser.id ? updatedUser : user
          ),
        });

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'User updated successfully.',
        });
      })
    );
  }

  @Action(AdminUserAction.RemoveUser)
  removeUser(
    ctx: StateContext<AdminUserStateModel>,
    action: AdminUserAction.RemoveUser
  ) {
    return this.userService.removeUser(action.userId).pipe(
      tap({
        next: (removedUser) => {
          const state = ctx.getState();

          ctx.patchState({
            users: state.users.filter((user) => user.id !== removedUser.id),
          });

          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Nutzer wurde erfolgreich gelöscht.',
          });
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: 'Nutzer konnte nicht gelöscht werden.',
          });
        },
      })
    );
  }

  @Action(AdminUserAction.SendActivationEmail)
  sendActivationEmail(
    ctx: StateContext<AdminUserStateModel>,
    action: AdminUserAction.SendActivationEmail
  ) {
    return this.userService.sendActivationEmail(action.userId).pipe(
      tap({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Activation email was sent successfully.',
          });
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Sending activation email unsuccessfull.',
          });
        },
      })
    );
  }

  @Action(AdminUserAction.GetResetPasswordLink)
  getResetPasswordLink(ctx: StateContext<AdminUserStateModel>, action: AdminUserAction.GetResetPasswordLink) {
    return this.authService.getResetPasswordLink(action.userId).pipe(
      tap({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Der Link wurde erfolgreich generiert.',
          });
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: 'Nutzer konnte nicht gelöscht werden.',
          });
        },
      })
    );
  }
}
