import { Component } from '@angular/core';
import { LoadingDialogService } from '../../services/loading-dialog.service';
import { DestroyStreamComponent } from '../destroy-stream/destroy-stream.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent extends DestroyStreamComponent {

  isLoading: boolean = false;

  constructor(public loadingDialogService: LoadingDialogService) {
    super()
  }

  ngOnInit(): void {
    this.loadingDialogService.isLoading$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isLoading => this.isLoading = isLoading)
  }
}
