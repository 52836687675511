import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CurrentUserStateModel } from './current_user.model';
import { CurrentUser } from './current_user.action';
import { CURRENT_USER_STATE } from '../index';
import { UserService } from '../../shared/services/user.service';
import { tap } from 'rxjs';
import { Org } from 'src/app/org/org-state/org.action';
import { MessageService } from 'primeng/api';
import { nullifyEmptyString } from 'src/app/shared/utils/nullifyEmptyString';
import { UserSelf } from '../../shared/graphql/generated/graphql';
import {
  AsyncInitializationService,
  AsyncInitializationTaskState,
} from '../../shared/services/async-initialization.service';
import { AuthState } from '../auth/auth.state';
import { Auth } from '../auth/auth.action';
import { IAccessibleDataset, IAccessibleDatasetData } from "../../shared/model/accessible-dataset.interface";

@State<CurrentUserStateModel>({
  name: CURRENT_USER_STATE,
  defaults: {
    loading: false,
  },
})
@Injectable()
export class CurrentUserState {
  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private asyncInitializationService: AsyncInitializationService,
    private store: Store
  ) {}

  @Selector()
  static profile(state: CurrentUserStateModel) {
    return state.profile;
  }

  @Selector()
  static fullName(state: CurrentUserStateModel) {
    return `${state.profile?.firstName} ${state.profile?.lastName}`;
  }

  @Selector()
  static orgName(state: CurrentUserStateModel) {
    return state.profile?.organisation?.name;
  }

  @Selector()
  static orgId(state: CurrentUserStateModel) {
    return state.profile?.organisation?.id;
  }

  @Selector()
  static userId(state: CurrentUserStateModel) {
    return state.userId;
  }
  @Selector()
  static claims(state: CurrentUserStateModel) {
    return state.claims;
  }

  @Selector()
  static accessibleDatasets(state: CurrentUserStateModel) {
    return state.accessibleDatasets;
  }

  @Action(CurrentUser.LoadUser)
  loadUser({
    getState,
    patchState,
    dispatch,
  }: StateContext<CurrentUserStateModel>) {
    if (getState().loading) {
      return;
    }
    patchState({ loading: true });
    this.asyncInitializationService.updateTaskState(
      'current-user',
      AsyncInitializationTaskState.PENDING
    );
    this.userService.getUserSelf().subscribe((userSelf) => {
      if (userSelf) {
        patchState({
          profile: userSelf,
          userId: userSelf.id,
        });

        const accessibleDatasets = userSelf.accessible_datasets as { [key: string]: IAccessibleDatasetData };
        const accessibleDatasetsMap = new Map<string, IAccessibleDatasetData>();
        Object.keys(accessibleDatasets).forEach((key) => {
          accessibleDatasetsMap.set(key, accessibleDatasets[key] as IAccessibleDataset);
        });

        this.store.dispatch(
          new Auth.UpdateClaimsAndDatasetsPermission(
            userSelf.claims as string[],
            accessibleDatasetsMap
          )
        );
        if (userSelf.isOrgAdmin) {
          dispatch(new Org.LoadCurrentOrg(userSelf.organisation?.id ?? ''));
        }
        this.asyncInitializationService.updateTaskState(
          'current-user',
          AsyncInitializationTaskState.SUCCESS
        );
      } else {
        console.error(
          '[CurrentUserState] Error while fetching current user',
          userSelf
        );
        this.asyncInitializationService.updateTaskState(
          'current-user',
          AsyncInitializationTaskState.ERROR
        );
      }

      patchState({ loading: false });
    });
  }

  @Action(CurrentUser.UpdateProfile)
  updateProfile(
    { patchState, getState }: StateContext<CurrentUserStateModel>,
    { userEditProfileInput }: CurrentUser.UpdateProfile
  ) {
    const payload = nullifyEmptyString(userEditProfileInput);
    const currentProfile = { ...getState().profile };
    return this.userService.editProfile(payload).pipe(
      tap((userSelf) => {
        currentProfile.firstName = userSelf.firstName; // ToDo: Fix API-Side (return org in user resolver), this is a workaround
        currentProfile.lastName = userSelf.lastName;
        currentProfile.email = userSelf.email;
        currentProfile.phone = userSelf.phone;
        currentProfile.department = userSelf.department;
        patchState({
          profile: currentProfile as UserSelf,
        });
        this.messageService.add({
          severity: 'success',
          summary: 'Gespeichert',
          detail: 'Profil erfolgreich aktualisiert.',
        });
      })
    );
  }
}
