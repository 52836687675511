// import { SetFilterSettingInput } from './../graphql/generated/graphql';
import { Injectable } from '@angular/core';
import {
  FilterSettingSetGQL,
  FilterSetting,
  FilterSettingsBelongToUserGQL,
  SetFilterSettingInput,
} from '../graphql/generated/graphql';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(
    private fetchFilterSettingsGQL: FilterSettingsBelongToUserGQL,
    private setFilterSettingGQL: FilterSettingSetGQL
  ) {}

  fetchFilterSettings() {
    return this.fetchFilterSettingsGQL
      .fetch()
      .pipe(map((response) => response.data.filterSettingsBelongToUser as FilterSetting[]));
  }

  setFilterSetting(setFilterSettingInput: SetFilterSettingInput) {
    return this.setFilterSettingGQL
      .mutate({ setFilterSettingInput })
      .pipe(map((result) => result.data?.filterSettingSet as FilterSetting));
  }
}
