import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {
  SpecialAnalysis,
  SpecialAnalysisGQL,
  CreateSpecialAnalysisInput,
  SpecialAnalysisCreateGQL,
  UpdateSpecialAnalysisInput,
  SpecialAnalysisUpdateGQL,
  SpecialAnalysisRemoveGQL,
  SpecialAnalysesGQL
} from '../graphql/generated/graphql';


@Injectable({
  providedIn: 'root',
})
export class SpecialAnalysisService {

  constructor(
    private specialAnalysisGQL: SpecialAnalysisGQL,
    private specialAnalysesGQL: SpecialAnalysesGQL,
    private specialAnalysisCreateGQL: SpecialAnalysisCreateGQL,
    private specialAnalysisUpdateGQL: SpecialAnalysisUpdateGQL,
    private specialAnalysisRemoveGQL: SpecialAnalysisRemoveGQL,
  ) {
  }

  getSpecialAnalysis(specialAnalysisId: number) {
    return this.specialAnalysisGQL.watch({ specialAnalysisId }).valueChanges.pipe(map((res) => res.data.specialAnalysis as SpecialAnalysis));
  }

  loadSpecialAnalyses() {
    return this.specialAnalysesGQL.watch().valueChanges.pipe(
      map((res) => res.data.specialAnalyses as SpecialAnalysis[])
    )
  }

  createSpecialAnalysis(createSpecialAnalysisInput: CreateSpecialAnalysisInput) {
    return this.specialAnalysisCreateGQL
      .mutate({ createSpecialAnalysisInput })
      .pipe(map((response) => response.data?.specialAnalysisCreate as SpecialAnalysis))
  }

  updateSpecialAnalysis(updateSpecialAnalysisInput: UpdateSpecialAnalysisInput) {
    return this.specialAnalysisUpdateGQL
      .mutate({ updateSpecialAnalysisInput })
      .pipe(map((result) => result.data?.specialAnalysisUpdate as SpecialAnalysis))
  }

  removeSpecialAnalysis(specialAnalysisRemoveId: number) {
    return this.specialAnalysisRemoveGQL
      .mutate({ specialAnalysisRemoveId })
      .pipe(map((result) => result.data?.specialAnalysisRemove as SpecialAnalysis))
  }
}
