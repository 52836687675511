import { CreateUserInput, UpdateUserInput } from "src/app/shared/graphql/generated/graphql";

export namespace AdminUserAction {

  export class CreateUser {
    static readonly type = '[Admin User] CreateUser';
    constructor(public createUserInput: CreateUserInput) { }
  }

  export class LoadUsers {
    static readonly type = '[Admin User] LoadUsers';
  }

  export class UpdateUser {
    static readonly type = '[Admin User] UpdateUser';
    constructor(public updateUserInput: UpdateUserInput) { }
  }

  export class ToggleActive {
    static readonly type = '[Admin User] ToggleActive';
    constructor(public userId: string) { }
  }

  export class RemoveUser {
    static readonly type = '[Admin User] RemoveUser';
    constructor(public userId: string) { }
  }

  export class SendActivationEmail {
    static readonly type = '[Admin User] SendActivationEmail';
    constructor(public userId: string) { }
  }

  export class GetResetPasswordLink {
    static readonly type = '[Admin User] GetResetPasswordLink';
    constructor(public userId: string) {}
  }

  export class SetUserPassword {
    static readonly type = '[Admin User] SetUserPassword';
    constructor(public userId: string, public newPassword: string, public repeatPassword: string) {}
  }
}
