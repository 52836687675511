import {Injectable} from '@angular/core';
import {CustomErrorCode} from '../graphql/generated/graphql';
import {ApolloError} from '@apollo/client/core';
import { GraphQLError } from "graphql/error";

interface ErrorCodeMapping {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorCodeService {
  constructor() {}

  private errorCodeMapping: ErrorCodeMapping = {
    [CustomErrorCode.AuthIncorrectPasswordChange]: 'Aktuelles Passwort inkorrekt',
    [CustomErrorCode.AuthLoginInvalidEmailOrPassword]:
      'E-Mail oder Passwort falsch. Bitte versuchen Sie es erneut.',
    [CustomErrorCode.AuthTokenExpired]: 'Session abgelaufen',
    [CustomErrorCode.AuthTokenInvalid]: 'Ungültiges Token',
    [CustomErrorCode.DatasetAccessDenied]: 'Zugriff auf Dataset verweigert',
    [CustomErrorCode.DatasetNotFound]: 'Dataset nicht gefunden',
    [CustomErrorCode.DatasetNotInDb]: 'Dataset nicht gefunden',
    [CustomErrorCode.DatasetProductAccessDenied]:
      'Zugriff auf Dataset verweigert. Sie besitzen das Produkt nicht',
    [CustomErrorCode.DatasetSpAnalysisAccessDenied]:
      'Zugriff auf Dataset verweigert. Sie besitzen die Sonderanalyse nicht',
    [CustomErrorCode.EntityAlreadyExists]: 'Existiert bereits',
    [CustomErrorCode.EntityNotFound]: 'Nicht gefunden',
    [CustomErrorCode.FilterSettingAlreadyExists]:
      'Filter setting already exists',
    [CustomErrorCode.FilterSettingNotFound]: 'Filter setting not found',
    [CustomErrorCode.FilterSettingProductMismatch]:
      'Filter setting does not match the product',
    [CustomErrorCode.InsufficientAccessLevel]: 'Insufficient access level',
    [CustomErrorCode.InsufficientOrgAccessLevel]:
      'Insufficient organization access level',
    [CustomErrorCode.InternalServerError]: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte den Support.',
    [CustomErrorCode.NoEntityIdProvided]: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte den Support.',
    [CustomErrorCode.OrgAdminDeleteOtherOrgUserAttempt]:
      'Organization admin cannot delete users from another organization',
    [CustomErrorCode.OrgAlreadyOwnsProduct]:
      'Organization already owns this product',
    [CustomErrorCode.OrgAlreadyOwnsSpAnalysis]:
      'Organization already owns this special analysis',
    [CustomErrorCode.OrgAnalysisAddWithoutOwningProduct]:
      'Cannot add analysis without owning the product',
    [CustomErrorCode.OrgNotFound]: 'Organization not found',
    [CustomErrorCode.ProductNotFound]: 'Product not found',
    [CustomErrorCode.RateLimitExceeded]: 'Zu viele Anfragen. Bitte versuchen Sie es später erneut.',
    [CustomErrorCode.SpecialAnalysisNotFound]: 'Special analysis not found',
    [CustomErrorCode.UnauthorizedAccess]: 'Unauthorized access',
    [CustomErrorCode.UnauthorizedOperation]: 'Unauthorized operation',
    [CustomErrorCode.UnauthorizedUserCreate]: 'Unauthorized to create user',
    [CustomErrorCode.UnauthorizedUserDelete]: 'Unauthorized to delete user',
    [CustomErrorCode.UserAccountLocked]: 'Ihr Account wurde gesperrt. Bitte kontaktieren Sie den Support.',
    [CustomErrorCode.UserAccountNotActivated]:
      'Ihr Account ist noch nicht aktiviert. Bitte überprüfen Sie Ihre E-Mails.',
    [CustomErrorCode.UserAlreadyActivated]: 'Account bereits aktiviert. Bitte loggen Sie sich ein.',
    [CustomErrorCode.UserDeleteOwnAccountAttempt]:
      'Nutzer können ihren eigenen Account nicht löschen',
    [CustomErrorCode.UserNotFound]: 'Nutzer nicht gefunden',
    [CustomErrorCode.UserNotInCurrentOrg]:
      'Nutzer ist nicht Teil ihrer Organisation',
    [CustomErrorCode.UserNoOrganization]:
      'Nutzer ist keiner Organisation zugeordnet',
    [CustomErrorCode.UserOrgDoesNotOwnProduct]:
      "Ihre Organisation besitzt das Produkt nicht. Bitte kontaktieren Sie den Support, um das Produkt zu erwerben.",
    [CustomErrorCode.UserOrgLicenseExpired]:
      'Ihre Lizenz ist abgelaufen. Bitte erneuern Sie Ihre Lizenz, um den Service weiterhin nutzen zu können.',
    [CustomErrorCode.UserOrgLocked]: 'Ihre Organisation wurde gesperrt. Bitte kontaktieren Sie den Support.',
    [CustomErrorCode.UserOrgTrialExpired]:
      "Ihr Testzeitraum ist abgelaufen. Bitte erneuern Sie Ihre Lizenz, um den Service weiterhin nutzen zu können.",
    [CustomErrorCode.UnlistedError]:
      'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte den Support.',
  };

  getErrorCode(error: ApolloError | GraphQLError | Record<string, any>) {
    if (error instanceof ApolloError) {
      const code = error.graphQLErrors[0]?.extensions.code;

      return code as string;
    }
    if (error instanceof GraphQLError) {
      const code = error.extensions?.code;
      return code as string;
    }
    // if object has code property
    if (error.hasOwnProperty('extensions') && error['extensions'].hasOwnProperty('code')) {
      const code = error['extensions']['code'];
      return code as string;
    }
    return CustomErrorCode.UnlistedError;
  }

  getErrorMessage(code: string) {
    return this.errorCodeMapping[code] ?? 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte den Support.';
  }
}
