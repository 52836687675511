import { CreateOrganisationInput, UpdateOrganisationInput } from "src/app/shared/graphql/generated/graphql";

export namespace AdminOrgAction {
  export class LoadOrgs {
    static readonly type = '[Org] LoadOrgs';
  }

  export class CreateOrg {
    static readonly type = '[Org] CreateOrg';
    constructor(public organisationCreateInput: CreateOrganisationInput, public redirectToOrgAfterCreate: boolean) { }
  }

  export class UpdateOrg {
    static readonly type = '[Org] UpdateOrg';
    constructor(public organisationUpdateInput: UpdateOrganisationInput) { }
  }

  export class UpdateLicense {
    static readonly type = '[Org] UpdateLicense';
    constructor(public updateLicenseInput: UpdateOrganisationInput) { }
  }

  export class ToggleActive {
    static readonly type = '[Org] ToggleActive';
    constructor(public orgId: string) { }
  }

  export class RemoveOrg {
    static readonly type = '[Org] RemoveOrg';
    constructor(public orgId: string) { }
  }

  export class AddProduct {
    static readonly type = '[Org] AddProduct';
    constructor(public organisationId: string, public productId: number) { }
  }

  export class RemoveProduct {
    static readonly type = '[Org] RemoveProduct';
    constructor(public organisationId: string, public productId: number) { }
  }

  export class AddSpecialAnalysis {
    static readonly type = '[Org] AddSpecialAnalysis';
    constructor(public organisationId: string, public specialAnalysisId: number) { }
  }

  export class RemoveSpecialAnalysis {
    static readonly type = '[Org] RemoveSpecialAnalysis';
    constructor(public organisationId: string, public specialAnalysisId: number) { }
  }
}
