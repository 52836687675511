import { CreateSpecialAnalysisInput, SpecialAnalysis, UpdateSpecialAnalysisInput } from "../../shared/graphql/generated/graphql";

export namespace SpecialAnalysisAction {

  export class SelectSpecialAnalysisById {
    static readonly type = '[SpecialAnalysis] SelectSpecialAnalysisById';
    constructor(public id: number, public redirect: boolean = true) { }
  }

  export class LoadSpecialAnalyses {
    static readonly type = '[SpecialAnalysis] LoadSpecialAnalyses';
  }

  export class CreateSpecialAnalysis {
    static readonly type = '[SpecialAnalysis] CreateSpecialAnalysis';
    constructor(public createSpecialAnalysisInput: CreateSpecialAnalysisInput) { }
  }

  export class UpdateSpecialAnalysis {
    static readonly type = '[SpecialAnalysis] UpdateSpecialAnalysis';
    constructor(public updateSpecialAnalysisInput: UpdateSpecialAnalysisInput) { }
  }

  export class RemoveSpecialAnalysis {
    static readonly type = '[SpecialAnalysis] RemoveSpecialAnalysis';
    constructor(public id: number) { }
  }
}
