import {Component} from '@angular/core';
import {AsyncInitializationService} from "../../services/async-initialization.service";

@Component({
  selector: 'app-async-initialization-loading-overlay',
  templateUrl: './async-initialization-loading-overlay.component.html',
  styleUrls: ['./async-initialization-loading-overlay.component.scss']
})
export class AsyncInitializationLoadingOverlayComponent {

  constructor(public asyncInitializationService: AsyncInitializationService) {
  }

}
