import { SetFilterSettingInput } from '../../shared/graphql/generated/graphql';

export namespace SettingsAction {
  export class Initialize {
    static readonly type = '[Product] Initialize';
  }

  export class LoadCurrentUserFilterSettings {
    static readonly type = '[Settings] GetCurrentUserFilterSetting';
    constructor() {}
  }

  export class SetProductFilterSetting {
    static readonly type = '[Settings] SetProductFilterSetting';
    constructor(public setFilterSettingInput: SetFilterSettingInput) {}
  }
}
