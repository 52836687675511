<div *ngIf="asyncInitializationService.tasksArePending$ | async" class="loading-overlay">
    <!-- *ngIf="asyncInitializationService.tasksArePending$ | async"  -->
    <div class="flex flex-column">
        <div class="flex align-items-center mb-4">
            <img alt="image" src="/assets/images/stamo-icon.png" style="height: 50px;">
            <div class="flex flex-column ml-1 py-0 gap-0">
                <span class="font-bold mb-0 text-3xl" style="letter-spacing: 1px">STANDORTMONITOR</span>
                <span class="mt-0 text-sm">Umgebung wird geladen...</span>
            </div>
        </div>
        <div class="pl-3">
            <p-progressBar [style]="{ height: '4px' }" mode="indeterminate"></p-progressBar>
        </div>
    </div>
</div>

<div *ngIf="asyncInitializationService.tasksCompletedWithErrors$ | async" class="loading-overlay">
    <!-- *ngIf="asyncInitializationService.tasksArePending$ | async"  -->
    <div class="flex flex-column justify-content-center text-center">
        <div class="flex align-items-center mb-7 justify-content-center mr-3">
            <img alt="image" src="/assets/images/stamo-icon.png" style="height: 50px;">
            <div class="flex flex-column ml-1 py-0 gap-0">
                <span class="font-bold mb-0 text-3xl" style="letter-spacing: 1px">STANDORTMONITOR</span>
            </div>
        </div>
        <span class="font-semibold text-2xl mb-3">Es ist ein Fehler aufgetreten</span>
        <span class="text-lg">Bitte versuchen Sie es später erneut</span>
        <span class="text-lg">Falls das Problem weiterhin besteht, wenden Sie sich bitte an den Support</span>
        <p-button (onClick)="asyncInitializationService.startAsyncTasksIfNotPendingOrComplete()" class="mt-6"
                  label="Erneut versuchen"></p-button>
    </div>
</div>
