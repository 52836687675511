export interface MenuItem {
  label?: string;
  icon?: string | undefined;
  routerLink?: string | string[] | undefined;
  url?: string | string[] | undefined;
  target?: string | undefined;
  items?: MenuItem[] | undefined;
  requiredClaims?: string[] | undefined;
  hideWhenNoItems?: boolean | undefined;
  separator?: boolean | undefined;
  visible?: boolean | undefined;
}

export const generalMenuItemsTop: MenuItem[] = [
  /*{
    icon: 'pi pi-home',
    visible: true,
    items: [
      {
        label: 'Home',
        icon: 'fa-solid fa-home',
        routerLink: ['/']
      },
    ]
  },*/
];

export const generalMenuItemsBottom: MenuItem[] = [
  {
    label: 'Hilfe',
    icon: 'pi pi-fw pi-info-circle',
    items: [
      {
        label: 'Kontakt',
        routerLink: ['/help/contact']
      },
      {
        label: 'Disclaimer',
        routerLink: ['/help/disclaimer']
      },
      // {
      //   label: 'FAQ',
      //   routerLink: ['/help/faq']
      // },
      {
        label: 'Impressum',
        url: ['https://www.goldmedia.com/impressum/'],
        target: '_blank'
      }
    ]
  }
];
