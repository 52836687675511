import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Organisation, CreateOrganisationInput, UpdateOrganisationInput } from "src/app/shared/graphql/generated/graphql";
import { Observable, tap } from "rxjs";
import { MessageService } from "primeng/api";
import { OrgService } from "src/app/shared/services/org.service";
import { nullifyEmptyString } from "src/app/shared/utils/nullifyEmptyString";
import { ADMIN_ORG_STATE } from "src/app/state";
import { AdminOrgAction } from "./admin-org.action";
import { AdminOrgStateModel } from "./admin-org.model";

@State<AdminOrgStateModel>({
  name: ADMIN_ORG_STATE,
  defaults: {
    fetching: false,
    orgs: []
  }
})
@Injectable()
export class AdminOrgState {
  constructor(
    private orgService: OrgService,
    private messageService: MessageService,
    private router: Router
  ) { }

  @Selector()
  static fetching(state: AdminOrgStateModel) {
    return state.fetching;
  }

  @Selector()
  static orgs(state: AdminOrgStateModel) {
    return state.orgs as Organisation[];
  }

  @Selector()
  static orgById(state: AdminOrgStateModel) {
    return (orgId: string | null) => state.orgs.find(org => org.id === orgId)
  }

  @Selector()
  static orgOptions(state: AdminOrgStateModel) {
    return state.orgs.map(({ name, id }) => ({ name, id }))
  }

  @Selector()
  static orgTable(state: AdminOrgStateModel) {
    return state.orgs ? state.orgs.map(({ __typename, ...org }) => ({
      ...org,
      admins: org.users.filter((user) => user?.isAdmin).map((user) => user?.firstName + ' ' + user?.lastName).join(', '),
      products: org.products.map((product) => product?.name).join(', '),
      expiry: org.isTrial ? org.trialExpiresAt : org.licenseExpiresAt
    })) : []
  }

  @Action(AdminOrgAction.LoadOrgs)
  loadOrgs(ctx: StateContext<AdminOrgStateModel>) {
    ctx.patchState({ fetching: true })
    return this.orgService.loadOrgs().pipe(
      tap((orgs) => {
        ctx.patchState({
          fetching: false,
          orgs: [...orgs]
        })
      })
    )
  }

  @Action(AdminOrgAction.CreateOrg)
  createOrg(ctx: StateContext<AdminOrgStateModel>, action: AdminOrgAction.CreateOrg): Observable<Organisation> {

    const payload = nullifyEmptyString(action.organisationCreateInput) as CreateOrganisationInput

    return this.orgService.createOrg(payload).pipe(
      tap((newOrg) => {
        const state = ctx.getState()

        ctx.patchState({
          orgs: [...state.orgs, newOrg]
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'New organisation is created successfully.'
        })

        if (action.redirectToOrgAfterCreate) {
          this.router.navigateByUrl(`/administration/orgs/${newOrg.id}`)
        }
      })
    )
  }

  @Action(AdminOrgAction.UpdateOrg)
  updateOrg(ctx: StateContext<AdminOrgStateModel>, action: AdminOrgAction.UpdateOrg) {

    const payload = nullifyEmptyString(action.organisationUpdateInput) as UpdateOrganisationInput

    return this.orgService.updateOrg(payload).pipe(
      tap((updatedOrg) => {
        const state = ctx.getState()

        ctx.patchState({
          orgs: state.orgs.map((org) => org.id === updatedOrg.id ? updatedOrg : org)
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `${updatedOrg?.name} is updated successfully.`
        })
      })
    )

  }

  @Action(AdminOrgAction.ToggleActive)
  toggleActive(ctx: StateContext<AdminOrgStateModel>, action: AdminOrgAction.ToggleActive) {
    const org = ctx.getState().orgs.find((org) => org.id === action.orgId)

    const payload = {
      id: org!.id,
      isLocked: !org!.isLocked,
    } as UpdateOrganisationInput;

    return this.orgService.updateOrg(payload).pipe(
      tap((updatedOrg) => {
        const state = ctx.getState()

        ctx.patchState({
          orgs: state.orgs.map((org) => org.id === updatedOrg.id ? updatedOrg : org)
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `${org?.name} is now ${payload.isLocked ? 'locked' : 'unlocked'}.`
        })
      })
    )
  }

  @Action(AdminOrgAction.UpdateLicense)
  updateLicense(ctx: StateContext<AdminOrgStateModel>, action: AdminOrgAction.UpdateLicense) {
    return this.orgService.updateOrg(action.updateLicenseInput).pipe(
      tap((updatedOrg) => {
        const state = ctx.getState()

        ctx.patchState({
          orgs: state.orgs.map((org) => org.id === updatedOrg.id ? updatedOrg : org)
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `${updatedOrg?.name}'s license is updated successfully.`
        })
      })
    )
  }

  @Action(AdminOrgAction.RemoveOrg)
  removeOrg(ctx: StateContext<AdminOrgStateModel>, action: AdminOrgAction.RemoveOrg) {
    return this.orgService.removeOrg(action.orgId).pipe(
      tap({
        next: (removedOrg) => {
          const state = ctx.getState()

          ctx.patchState({
            orgs: state.orgs.filter((org) => org.id !== removedOrg.id)
          })

          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Org deleted successfully.'
          });

          this.router.navigateByUrl('/administration/orgs')
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Delete org failed.'
          })
        }
      })
    )
  }

  @Action(AdminOrgAction.AddProduct)
  addProduct(ctx: StateContext<AdminOrgStateModel>, { organisationId, productId }: AdminOrgAction.AddProduct) {
    return this.orgService.addProduct(organisationId, productId).pipe(
      tap((updatedOrg) => {
        const state = ctx.getState()

        ctx.patchState({
          orgs: state.orgs.map((org) => org.id === updatedOrg.id ? updatedOrg : org)
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `${updatedOrg?.name} is subscribed to the selected product successfully.`
        })
      })
    )
  }

  @Action(AdminOrgAction.RemoveProduct)
  removeProduct(ctx: StateContext<AdminOrgStateModel>, { organisationId, productId }: AdminOrgAction.RemoveProduct) {
    return this.orgService.removeProduct(organisationId, productId).pipe(
      tap((updatedOrg) => {
        const state = ctx.getState()

        ctx.patchState({
          orgs: state.orgs.map((org) => org.id === updatedOrg.id ? updatedOrg : org)
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `${updatedOrg?.name} is unsubscribed from the selected product successfully.`
        })
      })
    )
  }

  @Action(AdminOrgAction.AddSpecialAnalysis)
  addSpecialAnalysis(ctx: StateContext<AdminOrgStateModel>, { organisationId, specialAnalysisId }: AdminOrgAction.AddSpecialAnalysis) {
    return this.orgService.addSpecialAnalysis(organisationId, specialAnalysisId).pipe(
      tap((updatedOrg) => {
        const state = ctx.getState()

        ctx.patchState({
          orgs: state.orgs.map((org) => org.id === updatedOrg.id ? updatedOrg : org)
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `${updatedOrg?.name} is subscribed to the selected special analysis successfully.`
        })
      })
    )
  }

  @Action(AdminOrgAction.RemoveSpecialAnalysis)
  removeSpecialAnalysis(ctx: StateContext<AdminOrgStateModel>, { organisationId, specialAnalysisId }: AdminOrgAction.RemoveSpecialAnalysis) {
    return this.orgService.removeSpecialAnalysis(organisationId, specialAnalysisId).pipe(
      tap((updatedOrg) => {
        const state = ctx.getState()

        ctx.patchState({
          orgs: state.orgs.map((org) => org.id === updatedOrg.id ? updatedOrg : org)
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `${updatedOrg?.name} is unsubscribed from the selected special analysis successfully.`
        })
      })
    )
  }

}
