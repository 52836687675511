import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SearchTag } from 'src/app/dashboards/shared/interfaces/SearchTag';
import { Router } from '@angular/router';

type ResultProperties = ['metric_name', 'analysis_name', 'granularity_name'];

@Component({
  selector: 'app-search-result-item',
  templateUrl: './result-item.component.html',
  styleUrls: ['./result-item.component.scss'],
})
export class ResultItemComponent implements OnInit {
  @Input() result!: SearchTag;
  @Input() withIcon = false;

  constructor(private router: Router) {}

  get dashboardRoute() {
    const { product_id, analysis_id, metric_id, granularity_id } = this.result;

    return `/dashboards/${product_id}/${analysis_id}x${metric_id}x${granularity_id}`;
  }

  generateBreadcrumbMenuItem() {
    const resultProperties: ResultProperties = [
      'metric_name',
      'analysis_name',
      'granularity_name',
    ];
    const results: MenuItem[] = [];

    resultProperties.forEach((property) => {
      if (
        this.result[property] !== null &&
        this.result[property] !== undefined
      ) {
        results.push({ label: this.result[property] as string });
      }
    });

    return results;
  }

  navigateToDashboard() {
    const path = this.dashboardRoute;

    this.router.navigate([path]);
  }

  ngOnInit() {}
}
