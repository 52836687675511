import {Injectable} from '@angular/core';
import {Store} from "@ngxs/store";
import {BehaviorSubject, map, Observable, take} from "rxjs";
import {filter} from "rxjs/operators";
import {AuthState} from "../../state/auth/auth.state";
import {SettingsAction} from "../../state/settings/settings.action";
import {CurrentUser} from "../../state/current_user/current_user.action";
import {Products} from "../../state/products/products.action";
import {LocalDatasetsAction} from "../../state/local_datasets/local_datasets.action";


export enum AsyncInitializationTaskState {
  INITIAL = 'initial',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error'
}

interface IAsyncInitializationTask {
  id: string;
  task: () => any;
  timeout?: number;
}

export interface IConstructedAsyncInitializationTask extends IAsyncInitializationTask {
  state: AsyncInitializationTaskState;
  startedAt?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AsyncInitializationService {

  _tasks: IAsyncInitializationTask[] = [
    {
      id: 'loading-spinner',
      task: () => this.promiseTaskWrapper('loading-spinner', this._loadingSpinnerMinTimeTask),
    },
    {
      id: 'current-user',
      task: () => this.store.dispatch(new CurrentUser.LoadUser()),
      timeout: 30000
    },
    {
      id: 'user-filter-settings',
      task: () => this.store.dispatch(new SettingsAction.LoadCurrentUserFilterSettings()),
      timeout: 30000
    },
    {
      id: 'products',
      task: () => this.store.dispatch(new Products.LoadProducts()),
      timeout: 30000
    },
    {
      id: 'wz-classes',
      task: () => this.store.dispatch(new LocalDatasetsAction.LoadWzClassesFromRemote()),
      timeout: 30000
    },
    {
      id: 'sub-markets',
      task: () => this.store.dispatch(new LocalDatasetsAction.LoadSubMarketsFromRemote()),
      timeout: 30000
    },
    {
      id: 'industry-sub-markets',
      task: () => this.store.dispatch(new LocalDatasetsAction.LoadIndustrySubMarketsFromRemote()),
      timeout: 30000
    },
    {
      id: 'methodologies',
      task: () => this.store.dispatch(new LocalDatasetsAction.LoadMethodologiesFromRemote()),
      timeout: 30000
    },
    {
      id: 'regional-associations',
      task: () => this.store.dispatch(new LocalDatasetsAction.LoadRegionalAssociationsFromRemote()),
      timeout: 30000
    },
    {
      id: 'administrative-districts',
      task: () => this.store.dispatch(new LocalDatasetsAction.LoadAdministrativeDistrictsFromRemote()),
      timeout: 30000
    },
    {
      id: 'districts',
      task: () => this.store.dispatch(new LocalDatasetsAction.LoadDistrictsFromRemote()),
      timeout: 30000
    }
  ];

  _tasksStateSubject: BehaviorSubject<IConstructedAsyncInitializationTask[]>;
  _taskTimeouts: { [id: string]: any } = {};

  constructor(private store: Store) {
    this._tasksStateSubject = new BehaviorSubject<IConstructedAsyncInitializationTask[]>(this._tasks.map(task => ({
      ...task,
      state: AsyncInitializationTaskState.INITIAL
    })));
    this._tasksState$ = this._tasksStateSubject.asObservable();
  }

  _tasksState$: Observable<IConstructedAsyncInitializationTask[]>;

  get tasksState$(): Observable<IConstructedAsyncInitializationTask[]> {
    return this._tasksState$;
  }

  get tasksArePending$(): Observable<boolean> {
    return this._tasksState$.pipe(
        map(tasks => tasks.some(task => task.state === AsyncInitializationTaskState.PENDING))
    );
  }

  get tasksCompletedWithErrors$(): Observable<boolean> {
    return this._tasksState$.pipe(
        map(tasks => tasks.some(task => task.state === AsyncInitializationTaskState.ERROR) && tasks.every(task => task.state !== AsyncInitializationTaskState.PENDING))
    );
  }

  _loadingSpinnerMinTimeTask(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 700);
    });
  }

  promiseTaskWrapper(taskId: string, func: () => Promise<any>): void {
    func().then(() => {
      this.updateTaskState(taskId, AsyncInitializationTaskState.SUCCESS);
    }).catch(() => {
      this.updateTaskState(taskId, AsyncInitializationTaskState.ERROR);
    });
  }

  _checkTasksNeedToBeStarted(task: IConstructedAsyncInitializationTask): boolean {
    if (task.id == 'loading-spinner' && task.state != AsyncInitializationTaskState.PENDING) {
      return true; // always start loading spinner
    }
    return task.state == AsyncInitializationTaskState.INITIAL || task.state == AsyncInitializationTaskState.ERROR;

  }

  startAsyncTasksIfNotPendingOrComplete(): void {
    const tasks = this._tasksStateSubject.getValue();
    tasks.forEach(task => {
      if (this._checkTasksNeedToBeStarted(task)) {
        this.updateTaskState(task.id, AsyncInitializationTaskState.PENDING);
        task.task();
      }
    });
  }

  public updateTaskState(id: string, state: AsyncInitializationTaskState) {
    const tasks = this._tasksStateSubject.getValue();
    const taskIndex = tasks.findIndex(task => task.id === id);
    if (taskIndex !== -1) {
      tasks[taskIndex].state = state;
      this._tasksStateSubject.next(tasks);
      this._setupTimeout(tasks[taskIndex]);
    } else {
      console.warn(`[AsyncInitializationService] [${id}] Task not found`);
    }
  }

  public awaitTasksCompletion(): Promise<boolean> {
    // check auth from store
    let isLoggedIn = this.store.selectSnapshot(AuthState.isLoggedIn);
    if (!isLoggedIn) {
      console.debug('[AsyncInitializationService] [awaitTasksCompletion] User is not logged in, skipping async initialization...');
      return Promise.resolve(true);
    }

    this.startAsyncTasksIfNotPendingOrComplete();
    return new Promise((resolve, reject) => {
      this._tasksStateSubject.pipe(
          filter(tasks => tasks.every(task =>
              task.state === AsyncInitializationTaskState.SUCCESS)), // || task.state === AsyncInitializationTaskState.ERROR
          take(1)
      ).subscribe(tasks => {
        const allComplete = tasks.every(task => task.state === AsyncInitializationTaskState.SUCCESS);
        const anyError = tasks.some(task => task.state === AsyncInitializationTaskState.ERROR);


        if (allComplete && !anyError) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  }

  private _setupTimeout(task: IConstructedAsyncInitializationTask) {
    // Clear any existing timeout
    if (this._taskTimeouts[task.id]) {
      clearTimeout(this._taskTimeouts[task.id]);
      delete this._taskTimeouts[task.id];
    }

    // Set a new timeout if the task is not yet complete or in error
    if (task.state == AsyncInitializationTaskState.PENDING && task.timeout) {
      this._taskTimeouts[task.id] = setTimeout(() => {
        console.error(`[AsyncInitializationService] [${task.id}] Timed out`);
        this.updateTaskState(task.id, AsyncInitializationTaskState.ERROR);
      }, task.timeout);
    }
  }
}
