import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../layout/service/app.layout.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(private layoutService: LayoutService,) {
  }

  get dark(): boolean {
    return this.layoutService.config.colorScheme !== 'light';
  }

  ngOnInit(): void {

  }

}
