import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {tap} from "rxjs";
import {UserService} from "src/app/shared/services/user.service";
import {ORG_STATE} from "src/app/state";
import {CreateUserForOwnOrgInput, User} from "../../shared/graphql/generated/graphql";
import {OrgService} from "../../shared/services/org.service";
import {Org} from "./org.action";
import {OrgStateModel} from "./org.model";
import {MessageService} from "primeng/api";
import {nullifyEmptyString} from "src/app/shared/utils/nullifyEmptyString";
import { ErrorCodeService } from "src/app/shared/services/error-code.service";

@State<OrgStateModel>({
  name: ORG_STATE
})
@Injectable()
export class OrgState {
  constructor(
    private orgService: OrgService,
    private userService: UserService,
    private messageService: MessageService,
    private errorCodeService: ErrorCodeService,
  ) { }

  @Selector()
  static profile(state: OrgStateModel) {
    return state.currentOrgProfile
  }

  @Selector()
  static users(state: OrgStateModel) {
    return state.currentOrgUsers as User[];
  }

  @Selector()
  static fetching(state: OrgStateModel) {
    return state.fetching;
  }

  @Action(Org.LoadCurrentOrg)
  loadOrg(ctx: StateContext<OrgStateModel>, action: Org.LoadCurrentOrg) {
    this.orgService.getOrg(action.orgId).subscribe(
      (org) => {
        if (org) {
          ctx.patchState({
            currentOrgProfile: org,
            currentOrgUsers: org.users as User[]
          })
        } else {
        }
      }
    )
  }

  @Action(Org.UpdateCurrentOrgProfile)
  updateProfile(ctx: StateContext<OrgStateModel>, action: Org.UpdateCurrentOrgProfile) {
    const payload = nullifyEmptyString(action.organisationUpdateOwnInput);
    return this.orgService.updateOwnOrg(payload).pipe(
      tap((updatedOrg) => {
        ctx.patchState({
          currentOrgProfile: updatedOrg
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Gespeichert',
          detail: 'Organisation erfolgreich aktualisiert.'
        });
      })
    )
  }

  @Action(Org.CreateOrgUser)
  createOrgUser(ctx: StateContext<OrgStateModel>, action: Org.CreateOrgUser) {
    const payload = nullifyEmptyString(action.orgUserCreateInput) as CreateUserForOwnOrgInput;

    return this.orgService.createOrgUser(payload).pipe(
      tap({
        next: (newOrgUser) => {
          const state = ctx.getState();
          ctx.patchState({
            currentOrgUsers: [
              ...state.currentOrgUsers!,
              newOrgUser
            ]
          });

          this.messageService.add({
            severity: 'success',
            summary: 'Nutzer erstellt',
            detail: 'Nutzer erfolgreich erstellt und E-Mail gesendet.'
          })
        },
        error: (error) => {
         const errCode = this.errorCodeService.getErrorCode(error);
         const errMsg = this.errorCodeService.getErrorMessage(errCode);

         throw errMsg;
        }
      }));
  }

  @Action(Org.RemoveOrgUser)
  removeOrgUser(ctx: StateContext<OrgStateModel>, action: Org.RemoveOrgUser) {
    return this.orgService.removeOrgUser(action.userId).pipe(
      tap({
        next: (removedUser) => {
          const state = ctx.getState()
          ctx.patchState({
            currentOrgUsers: state.currentOrgUsers?.filter(user => user.id !== removedUser.id)
          })
          this.messageService.add({
            severity: 'success',
            summary: 'Nutzer gelöscht',
            detail: 'Nutzer wurde erfolgreich gelöscht.'
          })
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: 'Nutzer konnte nicht gelöscht werden.'
          })
        }
      })
    )
  }

}
