<ng-template #menuItemTpl let-description="description" let-icon="icon" let-link="link" let-onClick="onClick" let-title="title">
  <li>
    <a
      (click)="onMenuItemClick(onClick)"
      [routerLink]="link"
      routerLinkActive="text-primary"
      class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
      <span>
        <i class="pi {{ icon }} text-xl text-primary"></i>
      </span>
      <div class="ml-3">
        <span class="mb-2 font-semibold text-color">{{ title }}</span>
        <p class="text-color-secondary m-0">{{ description }}</p>
      </div>
    </a>
  </li>
</ng-template>

<ng-template #menuItemLoadingTpl>
  <li>
    <a class="flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round">
      <span>
        <p-skeleton width="1.25rem" height="1.25rem"></p-skeleton>
      </span>
      <div class="ml-3 w-full">
        <p-skeleton width="50%" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="80%" styleClass="m-0"></p-skeleton>
      </div>
    </a>
  </li>
</ng-template>

<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-profile-sidebar w-full sm:w-25rem">
  <ng-container *ngIf="(profile$ | async) as user">
    <div class="flex flex-column mx-auto md:mx-0">
        <ng-container *ngIf="user else loadingUserName">
          <span class="font-semibold mb-0 text-lg">{{user.firstName}} {{user.lastName}}</span>
          <span class="text-color-secondary mb-5" *ngIf="user.organisation">{{ user.organisation.name }}</span>
        </ng-container>

        <ng-template #loadingUserName>
          <p-skeleton width="80%" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="70%" styleClass="mb-5"></p-skeleton>
        </ng-template>

        <ul class="list-none m-0 p-0">
          <ng-container *ngIf="user else loadingMenuItems">
            <ng-template
              *ngTemplateOutlet="menuItemTpl; context: {title : 'Profil', description: 'Ihr Nutzer-Profil', icon: 'pi-user', link:['user/profile']} "></ng-template>
            <ng-container *ngIf="isUserOrgAdmin$ | async">
              <ng-template
                *ngTemplateOutlet="menuItemTpl; context: {title : 'Organisation', description: 'Verwalten Sie ihre Organisation', icon: 'pi-wrench', link:['org/info']} "></ng-template>
            </ng-container>
            <ng-template
              *ngTemplateOutlet="menuItemTpl; context: {title : 'Einstellungen', description: 'Voreinstellungen für Visualisierungen und Filter', icon: 'pi-cog', link:['settings'] } "></ng-template>
            <ng-template
              *ngTemplateOutlet="menuItemTpl; context: {title : 'Abmelden', description: 'Jetzt abmelden', icon: 'pi-sign-out', onClick: onSignOutClick.bind(this)} "></ng-template>
          </ng-container>

          <ng-template #loadingMenuItems>
            <ng-template *ngTemplateOutlet="menuItemLoadingTpl"></ng-template>
            <ng-template *ngTemplateOutlet="menuItemLoadingTpl"></ng-template>
            <ng-template *ngTemplateOutlet="menuItemLoadingTpl"></ng-template>
            <ng-template *ngTemplateOutlet="menuItemLoadingTpl"></ng-template>
          </ng-template>
        </ul>
    </div>
  </ng-container>
</p-sidebar>
