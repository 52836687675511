<a (click)="navigateToDashboard()" class="flex align-items-center py-2 flex-1 cursor-pointer">
  <div class="flex-1">
    <h2 class="text-base mb-2">{{ result.search_title }}</h2>
    <app-search-result-breadcrumb [items]="generateBreadcrumbMenuItem()" />
  </div>
  <span
    *ngIf="withIcon"
    class="border border-solid border-primary-500 p-2 flex hover:bg-primary-500 cursor-pointer hover:text-white"
  >
    <i
      class="pi pi-chevron-right align-items-center"
      style="font-size: 0.5rem"
    ></i>
  </span>
</a>
