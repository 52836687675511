import { Injectable } from '@angular/core';
import { MeGQL, UserSelf, UsersGQL, User, CreateUserInput, UserCreateGQL, UserUpdateGQL, UpdateUserInput, UserRemoveGQL, UserUpdateSelfGQL, UpdateSelfInput, UserResendActivationEmailGQL, UserActivationLinkGQL } from '../graphql/generated/graphql';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private meGQL: MeGQL,
    private userUpdateSelfGQL: UserUpdateSelfGQL,
    private usersGQL: UsersGQL,
    private userCreateGQL: UserCreateGQL,
    private userUpdateGQL: UserUpdateGQL,
    private userRemoveGQL: UserRemoveGQL,
    private userSendActivationEmailGQL: UserResendActivationEmailGQL,
    private userActivationLinkGQL: UserActivationLinkGQL
  ) { }

  getUserSelf() {
    return this.meGQL.watch().valueChanges.pipe(
      map((result) => result.data.me as UserSelf)
    )
  }

  getUsers() {
    return this.usersGQL.watch().valueChanges.pipe(
      map((result) => result.data.users as User[])
    )
  }

  editProfile(userUpdateSelfInput: UpdateSelfInput) {
    return this.userUpdateSelfGQL
      .mutate({ userUpdateSelfInput })
      .pipe(map((response) => response.data?.userUpdateSelf as UserSelf))
  }

  createUser(userCreateInput: CreateUserInput) {
    return this.userCreateGQL
      .mutate({ userCreateInput })
      .pipe(map((result) => result.data?.userCreate as User))
  }

  updateUser(userUpdateInput: UpdateUserInput) {
    return this.userUpdateGQL
      .mutate({ userUpdateInput })
      .pipe(map((result) => result.data?.userUpdate as User))
  }

  removeUser(userRemoveId: string) {
    return this.userRemoveGQL
      .mutate({ userRemoveId })
      .pipe(map((result) => result.data?.userRemove as User))
  }

  getUserActivationLink(userId: string) {
    return this.userActivationLinkGQL.watch({ userId }).valueChanges.pipe(
      map((result) => result.data.userActivationLink)
    )
  }

  sendActivationEmail(userId: string) {
    return this.userSendActivationEmailGQL
      .mutate({ resendActivationEmailInput: { id: userId } })
      .pipe(map((result) => result.data?.userResendActivationEmail))
  }
}
