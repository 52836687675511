import {Component, ElementRef} from '@angular/core';
import {LayoutService} from './service/app.layout.service';
import {Select, Store} from "@ngxs/store";
import {DropdownChangeEvent} from "primeng/dropdown";
import {Products} from "../state/products/products.action";
import {ProductsState} from "../state/products/products.state";
import {Observable} from "rxjs";
import {Product} from "../shared/graphql/generated/graphql";

@Component({
  selector: 'app-sidebar',
  templateUrl: './app.sidebar.component.html'
})
export class AppSidebarComponent {

  // model: MenuItem[] = [];
  @Select(ProductsState.accessibleProducts) accessibleProducts$!: Observable<Product[] | undefined>;
  @Select(ProductsState.productsLoading) productsLoading$!: Observable<boolean>;
  @Select(ProductsState.selectedProduct) selectedProduct$!: Observable<Product | undefined>;

  constructor(public layoutService: LayoutService, public el: ElementRef, private store: Store) {

  }

  setProduct(event?: DropdownChangeEvent) {
    this.store.dispatch(new Products.SelectProductById(event?.value.id));
  }

  // Used for debugging purpose to clear the selected product
  // Paste these code into product dropdown in the template file
  // [showClear]="true" (onClear)="unsetProduct()"
  unsetProduct() {
    this.store.dispatch(new Products.ClearSelectedProduct());
  }

}
