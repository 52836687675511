<div>
  <!--<p class="mb-1 text-sm text-600">Häufig von Kunden gesucht</p>
  <ul class="flex flex-wrap list-none p-0">
    <li *ngFor="let search of mostFrequentSearches" class="mr-2 mb-2 md:mb-0">
      <button
        class="text-sm border-0 text-500 p-button p-button-rounded p-button-sm hover:bg-primary hover:text-white"
        [style]="{ 'background-color': '#e9e2d6' }"
        (click)="toSearchPageWithQuery(search.query)"
      >
        {{ search.query }}
      </button>
    </li>
  </ul>-->
</div>
