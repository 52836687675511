import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {LocalDatasetsStateModel} from "./local_datasets.model";
import {LOCAL_DATASETS_STATE} from "../index";
import {LocalDatasetsService} from "src/app/shared/services/local-datasets.service";
import {LocalDatasetsAction} from "./local_datasets.action";
import {DatasetsService} from "../../shared/services/datasets.service";
import {
  AsyncInitializationService,
  AsyncInitializationTaskState
} from "../../shared/services/async-initialization.service";

@State<LocalDatasetsStateModel>({
  name: LOCAL_DATASETS_STATE,
  defaults: {
    states: new Map(),
    subMarkets: [],
    wzClasses: [],
    industrySubMarkets: [],
    methodologies: [],
    regionalAssociations: [],
    administrativeDistricts: [],
    districts: []
  }
})
@Injectable()
export class LocalDatasetsState {

  constructor(private localDatasetsService: LocalDatasetsService, private datasetsService: DatasetsService, private asyncInitializationService: AsyncInitializationService) {
  }

  @Selector()
  static states(state: LocalDatasetsStateModel) {
    return state.states;
  }

  @Selector()
  static administrativeDistricts(state: LocalDatasetsStateModel) {
    return state.administrativeDistricts;
  }

  @Selector()
  static regionalAssociations(state: LocalDatasetsStateModel) {
    return state.regionalAssociations;
  }

  @Selector()
  static districts(state: LocalDatasetsStateModel) {
    return state.districts;
  }

  @Selector()
  static wzClasses(state: LocalDatasetsStateModel) {
    return state.wzClasses;
  }

  @Selector()
  static subMarkets(state: LocalDatasetsStateModel) {
    return state.subMarkets;
  }

  @Selector()
  static industrySubMarkets(state: LocalDatasetsStateModel) {
    return state.industrySubMarkets;
  }

  @Selector()
  static methodologies(state: LocalDatasetsStateModel) {
    return state.methodologies;
  }

  @Selector()
  static sumSubMarketIds(state: LocalDatasetsStateModel): number[] {
    return state.subMarkets.filter((subMarket) => subMarket.is_sum_sub_market).map((subMarket) => subMarket.id);
  }

  @Selector()
  static sumSubMarketDisplayNames(state: LocalDatasetsStateModel): string[] {
    return state.subMarkets.filter((subMarket) => subMarket.is_sum_sub_market).map((subMarket) => subMarket.display_name);
  }

  @Action(LocalDatasetsAction.Initialize)
  initialize(ctx: StateContext<LocalDatasetsStateModel>) {
    ctx.patchState({
      states: this.localDatasetsService.getStatesMap()
    })
  }

  @Action(LocalDatasetsAction.LoadWzClassesFromRemote)
  loadWzClassesFromRemote(ctx: StateContext<LocalDatasetsStateModel>) {
    this.asyncInitializationService.updateTaskState('wz-classes', AsyncInitializationTaskState.PENDING);
    return this.datasetsService.getDataset('ds__wz08p8__sm__vm', new Map()).then((wzClasses) => {
      ctx.patchState({
        wzClasses: wzClasses
        // .map((subMarket) => ({
        //   id: parseInt(subMarket.id),
        //   name: subMarket.display_name,

        // }))
      })
      this.asyncInitializationService.updateTaskState('wz-classes', AsyncInitializationTaskState.SUCCESS);
    }, (error) => {
      console.error('[LocalDatasetsState] Error loading wz classes from remote: ', error);
      this.asyncInitializationService.updateTaskState('wz-classes', AsyncInitializationTaskState.ERROR);
    })
  }

  @Action(LocalDatasetsAction.LoadSubMarketsFromRemote)
  loadSubMarketsFromRemote(ctx: StateContext<LocalDatasetsStateModel>) {
    this.asyncInitializationService.updateTaskState('sub-markets', AsyncInitializationTaskState.PENDING);
    return this.datasetsService.getDataset('ds__sub_markets__vm', new Map()).then((subMarkets) => {
      ctx.patchState({
        subMarkets: subMarkets.map((subMarket) => ({
          id: parseInt(subMarket.id),
          name: subMarket.display_name,
          display_name: subMarket.display_name,
          methodology_id: subMarket.methodology_id,
          is_sum_sub_market: subMarket.is_sum_sub_market == 'true',
        }))
      })
      this.asyncInitializationService.updateTaskState('sub-markets', AsyncInitializationTaskState.SUCCESS);
    }, (error) => {
      console.error('[LocalDatasetsState] Error loading sub markets from remote: ', error);
      this.asyncInitializationService.updateTaskState('sub-markets', AsyncInitializationTaskState.ERROR);
    })
  }

  @Action(LocalDatasetsAction.LoadIndustrySubMarketsFromRemote)
  loadIndustrySubMarketsFromRemote(ctx: StateContext<LocalDatasetsStateModel>) {
    this.asyncInitializationService.updateTaskState('industry-sub-markets', AsyncInitializationTaskState.PENDING);
    return this.datasetsService.getDataset('ds__industry_sub_markets__vm', new Map()).then((subMarkets) => {
      ctx.patchState({
        industrySubMarkets: subMarkets.map((subMarket) => ({
          id: parseInt(subMarket.id),
          name: subMarket.display_name,
          methodology_id: subMarket.methodology_id,
        }))
      })
      this.asyncInitializationService.updateTaskState('industry-sub-markets', AsyncInitializationTaskState.SUCCESS);
    }, (error) => {
      console.error('[LocalDatasetsState] Error loading industry sub markets from remote: ', error);
      this.asyncInitializationService.updateTaskState('industry-sub-markets', AsyncInitializationTaskState.ERROR);
    })
  }

  @Action(LocalDatasetsAction.LoadMethodologiesFromRemote)
  loadMethodologiesFromRemote(ctx: StateContext<LocalDatasetsStateModel>) {
    this.asyncInitializationService.updateTaskState('methodologies', AsyncInitializationTaskState.PENDING);
    return this.datasetsService.getDataset('ds__methodologies__vm', new Map()).then((methodologies) => {
      ctx.patchState({
        methodologies: methodologies.map((methodology) => ({
          id: parseInt(methodology.methodology_id),
          ...methodology,
        }))
      })
      this.asyncInitializationService.updateTaskState('methodologies', AsyncInitializationTaskState.SUCCESS);
    }, (error) => {
      console.error('[LocalDatasetsState] Error loading methodologies from remote: ', error);
      this.asyncInitializationService.updateTaskState('methodologies', AsyncInitializationTaskState.ERROR);
    })
  }

  @Action(LocalDatasetsAction.LoadRegionalAssociationsFromRemote)
  loadRegionalAssociationsFromRemote(ctx: StateContext<LocalDatasetsStateModel>) {
    this.asyncInitializationService.updateTaskState('regional-associations', AsyncInitializationTaskState.PENDING);
    return this.datasetsService.getDataset('ds__regional_associations__vm', new Map()).then((regionalAssociations) => {
      ctx.patchState({
        regionalAssociations: regionalAssociations.map((regional_associations) => ({
          id: parseInt(regional_associations.regional_association_id),
          ...regional_associations
        }))
      })
      this.asyncInitializationService.updateTaskState('regional-associations', AsyncInitializationTaskState.SUCCESS);
    }, (error) => {
      console.error('[LocalDatasetsState] Error loading regional-associations from remote: ', error);
      this.asyncInitializationService.updateTaskState('regional-associations', AsyncInitializationTaskState.ERROR);
    })
  }

  @Action(LocalDatasetsAction.LoadAdministrativeDistrictsFromRemote)
  loadAdministrativeDistrictsFromRemote(ctx: StateContext<LocalDatasetsStateModel>) {
    this.asyncInitializationService.updateTaskState('administrative-districts', AsyncInitializationTaskState.PENDING);
    return this.datasetsService.getDataset('ds__administrative_districts__vm', new Map()).then((administrativeDistricts) => {
      ctx.patchState({
        administrativeDistricts: administrativeDistricts.map((administrative_district) => ({
          id: parseInt(administrative_district.administrative_district_id),
          ...administrative_district
        }))
      })
      this.asyncInitializationService.updateTaskState('administrative-districts', AsyncInitializationTaskState.SUCCESS);
    }, (error) => {
      console.error('[LocalDatasetsState] Error loading administrative-districts from remote: ', error);
      this.asyncInitializationService.updateTaskState('administrative-districts', AsyncInitializationTaskState.ERROR);
    })
  }

  @Action(LocalDatasetsAction.LoadDistrictsFromRemote)
  loadDistrictsFromRemote(ctx: StateContext<LocalDatasetsStateModel>) {
    this.asyncInitializationService.updateTaskState('districts', AsyncInitializationTaskState.PENDING);
    return this.datasetsService.getDataset('ds__districts_vm', new Map()).then((districts) => {
      ctx.patchState({
        districts: districts.map((district) => ({
          id: parseInt(district.district_id),
          ...district
        }))
      })
      this.asyncInitializationService.updateTaskState('districts', AsyncInitializationTaskState.SUCCESS);
    }, (error) => {
      console.error('[LocalDatasetsState] Error loading districts from remote: ', error);
      this.asyncInitializationService.updateTaskState('districts', AsyncInitializationTaskState.ERROR);
    })
  }

}
