import { Action, Selector, State, StateContext } from "@ngxs/store";
import { RootStateModel } from "./root.model";
import { ROOT_STATE } from "../index";
import { Root } from "./root.action";
import { Injectable } from "@angular/core";

@State<RootStateModel>({
    name: ROOT_STATE,
    defaults: {

    }
})
@Injectable()
export class RootState {

}
