import {Action, createSelector, Selector, State, StateContext, Store} from "@ngxs/store";
import {PRODUCTS_STATE} from "../index";
import {Injectable} from "@angular/core";
import {ProductsStateModel} from "./products.model";
import {Products} from "./products.action";
import {FilterSetting, Product, UpdateProductInput} from "../../shared/graphql/generated/graphql";
import {catchError, filter, first, map, mergeMap, of, switchMap, tap} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {MenuItem} from "../../layout/app.menu.items";
import {Storage} from "../storage/storage.actions";
import {StorageState} from "../storage/storage.state";
import {AuthState} from "../auth/auth.state";
import {productMenuItems} from "./product-menu-items";
import {ProductsService} from "src/app/shared/services/products.service";
import {MessageService, TreeNode} from "primeng/api";
import {SettingsState} from "../settings/settings.state";
import {
  AsyncInitializationService,
  AsyncInitializationTaskState
} from "../../shared/services/async-initialization.service";

@State<ProductsStateModel>({
  name: PRODUCTS_STATE,
  defaults: {
    selectedProductId: undefined,
    selectedProduct: undefined,
    products: [],
    productsLoading: false,
    selectedProductMenuModel: [],
    fetching: false,
    settings: []
  }
})
@Injectable()
export class ProductsState {

  constructor(
    private router: Router,
    private store: Store,
    private productService: ProductsService,
    private messageService: MessageService,
    private asyncInitializationService: AsyncInitializationService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.toString().includes('/dashboards/')) {
          const res = new RegExp('\/dashboards\/(\\d+)').exec(event.url.toString());
          if (res && res.length > 1) {
            const productIdToSelect = parseInt(res[1]);
            this.store.dispatch(new Products.SelectProductById(productIdToSelect));
          }
        }
      }
    });
  }

  @Selector()
  static selectedProduct(state: ProductsStateModel) {
    return state.selectedProduct;
  }

  @Selector()
  static selectedProductMenuModel(state: ProductsStateModel) {
    return state.selectedProductMenuModel;
  }

  @Selector()
  static products(state: ProductsStateModel) {
    return state.products; //.sort((a, b) => a.id > b.id ? 1 : -1);
  }

  @Selector([AuthState.userClaims])
  static accessibleProducts(state: ProductsStateModel, userClaims: string[]): Product[] {
    if (state.products == undefined) return [];

    return state.products.filter(product => {
      if (userClaims.includes('admin_access')) return true;
      if (!product.isEnabled) return false;
      return userClaims.includes(`access_product_${product.id}`);
    });
  }

  @Selector()
  static productsLoading(state: ProductsStateModel) {
    return state.productsLoading;
  }

  @Selector()
  static fetching(state: ProductsStateModel) {
    return state.fetching;
  }

  @Selector()
  static productTable(state: ProductsStateModel) {
    return state.products;
  }

  @Selector()
  static productById(state: ProductsStateModel) {
    return (productId: number | null) => state.products.find((product) => product.id === productId)
  }

  @Selector()
  static productsTree(state: ProductsStateModel) {
    return state.products.map(({ specialAnalyses, ...product }) => ({
      label: product.name,
      data: product,
      expanded: true,
      children: specialAnalyses.map((specialAnalysis) => ({
        label: specialAnalysis.name,
        data: specialAnalysis,
        expanded: true,
      }))
    })) as TreeNode[]
  }

  @Selector()
  static productSettings(state: ProductsStateModel) {
    return state.settings
  }

  @Selector([SettingsState.filterSettings])
  static productSettingByProductId(state: ProductsStateModel, filterSettings: FilterSetting[]) {
    return (productId: number | null) => filterSettings.find((setting) => setting.productId === productId)
  }

  @Selector([SettingsState.filterSettings])
  static selectedProductSetting(state: ProductsStateModel, filterSettings: FilterSetting[]) {
    return filterSettings.find((setting) => setting.productId === state.selectedProduct?.id)
  }

  static productMethodologyIds(productId: number) {
    return createSelector([ProductsState], (state: ProductsStateModel) => {
      return state.products.find((product) => product.id === productId)?.productMethodologies.map((productMethodology) => productMethodology!.methodologyId) ?? [] as number[];
    });
  }

  @Action(Products.Initialize)
  async initialize({ dispatch, patchState }: StateContext<ProductsStateModel>) {
    patchState({
      productsLoading: true
    });

    return dispatch(new Products.LoadProducts()).pipe(
      tap(() => {
        patchState({
          productsLoading: false
        });
      }),
      mergeMap(() => dispatch(new Products.PreSelectProductByIdOnLoad()))
    );
  }

  @Action(Products.PreSelectProductByIdOnLoad)
  preSelectProductByIdOnLoad({ dispatch, getState }: StateContext<ProductsStateModel>) {
    // Return early when no accessible products are found
    return this.store.select(ProductsState.accessibleProducts).pipe(
      filter(accessibleProducts => accessibleProducts.length > 0), // Ignore emissions until accessibleProducts is not empty
      first(), // Take the first non-empty emission and then complete
      switchMap((accessibleProducts) => {
        // Get the options for preselecting the product
        const inMemorySelectedProductId = getState().selectedProductId;
        const inStorageSelectedProductId = this.store.selectSnapshot(StorageState.selectedProductId);
        const firstAccessibleProductId = accessibleProducts[0].id;

        // Preselect the product either from memory, storage, or the first accessible product
        const productIdToSelect = inMemorySelectedProductId ?? inStorageSelectedProductId ?? firstAccessibleProductId;
        return dispatch(new Products.SelectProductById(productIdToSelect))
      }),
      catchError((error) => {
        console.error('Error preselecting product:', error);
        return of(new Products.PreSelectProductByIdOnLoadFailed());
      })
    )
  }

  @Action(Products.PreSelectProductByIdOnLoadFailed)
  preSelectProductByIdOnLoadFailed(ctx: StateContext<ProductsStateModel>) {
    console.warn('[ProductsState] No accessible products found! - Preselecting product failed!');
  }

  _traverseMenuItemAndCheckClaims(menuItem: MenuItem) {
    if (menuItem.requiredClaims && menuItem.requiredClaims.length > 0 && !this.store.selectSnapshot(AuthState.hasClaims(menuItem.requiredClaims!))) return null;
    const tmp: MenuItem = { ...menuItem, items: [] };
    if (menuItem.items) {
      for (const childItem in menuItem.items) {
        const tmpChildItem = this._traverseMenuItemAndCheckClaims(menuItem.items[childItem]);
        if (tmpChildItem) tmp.items!.push(tmpChildItem);
      }
    }
    if (tmp.items!.length === 0 && tmp.hideWhenNoItems) return null;
    if (tmp.items!.length === 0) tmp.items = undefined;
    return tmp;
  };

  _generateMenuModel(product: any) {
    if (!product) return [];
    return productMenuItems.get(product.id)!.map(item => this._traverseMenuItemAndCheckClaims(item as MenuItem)).filter(item => item !== null) as MenuItem[];
  }

  @Action(Products.SelectProductById)
  selectProductById({
    patchState,
    getState,
    dispatch
  }: StateContext<ProductsStateModel>, { productId }: Products.SelectProductById) {
    const products = getState().products;

    // When Products not loaded yet, only set id and recall this method after products are loaded
    if (products === undefined || !products.length) {
      patchState({
        selectedProductId: productId,
      });
      return;
    }

    return this.store.select(ProductsState.accessibleProducts).pipe(
      filter(accessibleProducts => accessibleProducts.length > 0), // Ignore emissions until accessibleProducts is not empty
      first(), // Take the first non-empty emission and then complete
      map((accessibleProducts) => {
        const selectedProduct = accessibleProducts.find(product => product.id === productId);
        const isAccessible = selectedProduct !== undefined;
        if (!isAccessible) {
          return dispatch(new Products.UnauthorizedProductSelectionAttempt(productId));
        }

        // Bailed out when the selected product is already selected
        const currentState = getState();
        const isAlreadySelected = currentState.selectedProductId === productId && currentState.selectedProduct == selectedProduct
        if (isAlreadySelected) return;

        patchState({
          selectedProductId: productId,
          selectedProduct,
          selectedProductMenuModel: this._generateMenuModel(selectedProduct)
        });

        return dispatch(new Storage.SetSelectedProductId(productId));
      })
    )
  }

  @Action(Products.UnauthorizedProductSelectionAttempt)
  handleUnauthorizedProductSelectionAttempt({ dispatch }: StateContext<ProductsStateModel>, { productId }: Products.UnauthorizedProductSelectionAttempt) {
    console.warn(`[ProductsState] Unauthorized product ${productId} selection attempt.`);
    const accessibleProducts = this.store.selectSnapshot(ProductsState.accessibleProducts)
    dispatch(new Products.SelectProductById(accessibleProducts[0].id));
  }

  // Used for debugging purpose only
  @Action(Products.ClearSelectedProduct)
  clearSelectedProduct({ patchState, dispatch }: StateContext<ProductsStateModel>) {
    dispatch(new Storage.ClearSelectedProductId());
    patchState({
      selectedProductId: undefined,
      selectedProduct: undefined,
      selectedProductMenuModel: this._generateMenuModel(undefined)
    });
  }

  @Action(Products.LoadProducts)
  loadProducts({ patchState, getState, dispatch }: StateContext<ProductsStateModel>) {
    // if (getState().fetching) return; ToDo: Implement Error handling
    // patchState({ fetching: true })
    this.asyncInitializationService.updateTaskState('products', AsyncInitializationTaskState.PENDING);
    return this.productService.getProducts().pipe(
      tap((products) => {
            // sort products
            products = [...products];
            products.sort((a, b) => a.id > b.id ? 1 : -1);
        patchState({
          fetching: false,
          products
        });
            this.asyncInitializationService.updateTaskState('products', AsyncInitializationTaskState.SUCCESS);
        if (getState().selectedProductId !== undefined) {
          dispatch(new Products.SelectProductById(getState().selectedProductId!));
        }
      }
      ));
  }

  @Action(Products.CreateProduct)
  createProduct(ctx: StateContext<ProductsStateModel>, action: Products.CreateProduct) {
    return this.productService.createProduct(action.createProductInput).pipe(
      tap((newProduct) => {
        const state = ctx.getState()

        ctx.patchState({
          products: [...state.products!, newProduct]
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Product created successfully.'
        })
      })
    )
  }

  @Action(Products.UpdateProduct)
  updateProduct(ctx: StateContext<ProductsStateModel>, action: Products.UpdateProduct) {
    return this.productService.updateProduct(action.updateProductInput).pipe(
      tap((updatedProduct) => {
        const state = ctx.getState()

        ctx.patchState({
          products: state.products!.map((product) => product.id === updatedProduct.id ? updatedProduct : product)
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Product updated successfully.'
        })
      })
    )
  }

  @Action(Products.ToggleActive)
  toggleActive(ctx: StateContext<ProductsStateModel>, action: Products.ToggleActive) {
    const product = ctx.getState().products.find((product) => product.id === Number(action.id));

    const payload: UpdateProductInput = {
      id: product!.id,
      isEnabled: !product!.isEnabled
    }

    return this.productService.updateProduct(payload).pipe(
      tap((updatedProduct) => {
        const state = ctx.getState()

        ctx.patchState({
          products: state.products!.map((product) => product.id === updatedProduct.id ? updatedProduct : product)
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `${product?.name} is now ${payload.isEnabled ? 'enabled' : 'disabled'}.`
        })
      })
    )
  }

  @Action(Products.RemoveProduct)
  removeProduct(ctx: StateContext<ProductsStateModel>, action: Products.RemoveProduct) {
    return this.productService.removeProduct(action.productId).pipe(
      tap({
        next: (removedProduct) => {
          const state = ctx.getState()

          ctx.patchState({
            products: state.products!.filter((product) => product.id !== removedProduct.id)
          })

          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Product deleted successfully.'
          });

          this.router.navigateByUrl('/administration/products')
        },
        error: (err) => {
          console.error(err)
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Delete product failed.'
          })
        }
      })
    )
  }
}
