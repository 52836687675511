import { Injectable } from '@angular/core';
import { map } from "rxjs";
import { CreateProductInput, Product, ProductCreateGQL, ProductRemoveGQL, ProductsGQL, ProductUpdateGQL, UpdateProductInput } from "../graphql/generated/graphql";

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private productsGQL: ProductsGQL,
    private productCreateGQL: ProductCreateGQL,
    private productUpdateGQL: ProductUpdateGQL,
    private productRemoveGQL: ProductRemoveGQL
  ) { }

  getProducts() {
    return this.productsGQL.fetch().pipe(
      map((response) => response.data.products as Product[])
    )
  }

  createProduct(productCreateInput: CreateProductInput) {
    return this.productCreateGQL
      .mutate({ productCreateInput })
      .pipe(map((result) => result.data?.productCreate as Product))
  }

  updateProduct(productUpdateInput: UpdateProductInput) {
    return this.productUpdateGQL
      .mutate({ productUpdateInput })
      .pipe(map((result) => result.data?.productUpdate as Product))
  }

  removeProduct(productRemoveId: number) {
    return this.productRemoveGQL
      .mutate({ productRemoveId })
      .pipe(map((result) => result.data?.productRemove as Product))
  }
}
