import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalDatasetsService } from './local-datasets.service';
import Fuse from 'fuse.js';
import { SearchTag } from 'src/app/dashboards/shared/interfaces/SearchTag';
import { Store } from '@ngxs/store';
import { ProductsState } from 'src/app/state/products/products.state';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  searchText = '';
  searchResults: SearchTag[] = [];

  constructor(
    private localDatasetsService: LocalDatasetsService,
    private store: Store
  ) {}

  setSearchText(text: string) {
    this.searchText = text;
  }

  getSearchText() {
    return this.searchText;
  }

  getSelectedProduct() {
    return this.store.selectSnapshot(ProductsState.selectedProduct);
  }

  getRoutesBySelectedProductId() {
    const selectedProduct = this.getSelectedProduct();

    return this.localDatasetsService.getSearchRoutesByProductId(
      selectedProduct?.id as number
    );
  }

  get routesByProductId() {
    return this.getRoutesBySelectedProductId();
  }

  resetSearchResults() {
    this.searchResults = [];
  }

  onSearch(text: string) {
    const fuse = new Fuse<SearchTag>(this.routesByProductId, {
      keys: ['tags'],
    });

    this.searchResults = fuse
      .search(text)
      .filter(({ item }) => item.search_title)
      .map(({ item }) => item) as unknown as SearchTag[];
  }
}
