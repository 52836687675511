import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { SPECIAL_ANALYSIS_STATE } from "../index";
import { Injectable } from "@angular/core";
import { SpecialAnalysisStateModel } from "./special_analysis.model";
import { SpecialAnalysisAction } from "./special_analysis.action";
import { tap } from "rxjs";
import { Router } from "@angular/router";
import { SpecialAnalysisService } from "src/app/shared/services/special-analysis.service";
import { MessageService } from "primeng/api";

@State<SpecialAnalysisStateModel>({
  name: SPECIAL_ANALYSIS_STATE,
  defaults: {
    specialAnalyses: [],
    loading: false,
    fetching: false
  }
})
@Injectable()
export class SpecialAnalysisState {

  constructor(
    private router: Router,
    private specialAnalysisService: SpecialAnalysisService,
    private messageService: MessageService
  ) {
  }

  @Selector()
  static specialAnalyses(state: SpecialAnalysisStateModel) {
    return state.specialAnalyses;
  }

  @Selector()
  static isLoading(state: SpecialAnalysisStateModel) {
    return state.loading;
  }

  @Selector()
  static isFetching(state: SpecialAnalysisStateModel) {
    return state.fetching;
  }

  @Selector()
  static specialAnalysisTable(state: SpecialAnalysisStateModel) {
    return state.specialAnalyses.map(({ __typename, ...specialAnalysis }) => specialAnalysis);
  }

  @Selector()
  static specialAnalysisById(state: SpecialAnalysisStateModel) {
    return (specialAnalysisId: number | null) => state.specialAnalyses.find((specialAnalysis) => specialAnalysis.id === specialAnalysisId)
  }

  @Selector()
  static specialAnalysisByProductId(state: SpecialAnalysisStateModel) {
    return (productId: number | null) => state.specialAnalyses.filter((specialAnalysis) => specialAnalysis.productId === productId)
  }

  @Action(SpecialAnalysisAction.LoadSpecialAnalyses)
  loadSpecialAnalyses({ patchState, getState, dispatch }: StateContext<SpecialAnalysisStateModel>) {
    patchState({ fetching: true })
    return this.specialAnalysisService.loadSpecialAnalyses().pipe(
      tap((specialAnalyses) => {
        patchState({
          fetching: false,
          specialAnalyses: [...specialAnalyses]
        });
      }
      ));
  }

  @Action(SpecialAnalysisAction.CreateSpecialAnalysis)
  createSpecialAnalysis(ctx: StateContext<SpecialAnalysisStateModel>, action: SpecialAnalysisAction.CreateSpecialAnalysis) {
    return this.specialAnalysisService.createSpecialAnalysis(action.createSpecialAnalysisInput).pipe(
      tap((newSpecialAnalysis) => {
        const state = ctx.getState()

        ctx.patchState({
          specialAnalyses: [...state.specialAnalyses!, newSpecialAnalysis]
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Special Analysis created successfully.'
        })
      })
    )
  }

  @Action(SpecialAnalysisAction.UpdateSpecialAnalysis)
  updateSpecialAnalysis(ctx: StateContext<SpecialAnalysisStateModel>, action: SpecialAnalysisAction.UpdateSpecialAnalysis) {
    const state = ctx.getState()
    return this.specialAnalysisService.updateSpecialAnalysis(action.updateSpecialAnalysisInput).pipe(
      tap((updatedSpecialAnalysis) => {
        ctx.patchState({
          specialAnalyses: state.specialAnalyses!.map((specialAnalysis) => {
            return specialAnalysis.id === updatedSpecialAnalysis.id ? updatedSpecialAnalysis : specialAnalysis
          })
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Special Analysis updated successfully.'
        })
      })
    )
  }

  @Action(SpecialAnalysisAction.RemoveSpecialAnalysis)
  removeSpecialAnalysis(ctx: StateContext<SpecialAnalysisStateModel>, action: SpecialAnalysisAction.RemoveSpecialAnalysis) {
    return this.specialAnalysisService.removeSpecialAnalysis(action.id).pipe(
      tap({
        next: (removedSpecialAnalysis) => {
          const state = ctx.getState()

          ctx.patchState({
            specialAnalyses: state.specialAnalyses!.filter((specialAnalysis) => specialAnalysis.id !== removedSpecialAnalysis.id)
          })

          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Special Analysis deleted successfully.'
          });
        },
        error: (err) => {
          console.error(err)
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Deleting special analysis failed.'
          })
        }
      })
    )
  }
}
