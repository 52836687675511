import { IAccessibleDataset, IAccessibleDatasetData } from 'src/app/shared/model/accessible-dataset.interface';
import { TokenPair } from '../../shared/graphql/generated/graphql';

export namespace Auth {
  export class Initialize {
    static readonly type = '[Auth] Initialize';
  }

  export class Activateaccount {
    static readonly type = '[Auth] Activateaccount';

    constructor(public password: string, public token: string) {}
  }

  export class Login {
    static readonly type = '[Auth] Login';

    constructor(
      public email: string,
      public password: string,
      public rememberMe: boolean
    ) {}
  }

  export class LoginSuccess {
    static readonly type = '[Auth] LoginSuccess';
  }

  export class Logout {
    static readonly type = '[Auth] Logout';
  }

  export class SetNewPassword {
    static readonly type = '[Auth] SetNewPassword';

    constructor(public password: string, public token: string) {}
  }

  export class RefreshTokens {
    static readonly type = '[Auth] RefreshTokens';

    constructor(public refreshToken?: string) {}
  }

  export class UpdateTokens {
    static readonly type = '[Auth] UpdateTokens';

    constructor(public tokenPair: TokenPair) {}
  }

  export class NavigationFailed {
    static readonly type = '[Auth] NavigationFailed';
    constructor(public url: string) {}
  }

  export class UpdateClaimsAndDatasetsPermission {
    static readonly type = '[Auth] UpdateClaimsAndDatasetsPermission';

    constructor(
      public claims: string[],
      public accessibleDatasets: Map<string, IAccessibleDatasetData>
    ) {}
  }
}
